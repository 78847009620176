<app-loader
  *ngIf="loading"
  text="Loading..."
  class="padded"
  inline="true"
></app-loader>
<div *ngIf="!loading" class="pt-3 bill_tables">
  <div class="col-12 p-0">
    <div class="row ml-1">
      <div class="col-12 p-0">
        <p>
          <i
            >For the period of
            <b
              >{{
                rnOrganizationPaymentOnFileVM?.NextBillDate | date: "MMMM d, y"
              }}
              -
              {{
                rnOrganizationPaymentOnFileVM?.NextBillEndDate
                  | date: "MMMM d, y"
              }}</b
            >.</i
          >
        </p>
      </div>
    </div>
    <div class="row ml-1 mb-2">
      <div class="col-12 p-0">
        <span class="tables_title">Recurring Monthly Charges</span>
      </div>
    </div>
    <div class="row ml-1">
      <div class="col-md-12 p-0">
        <h5>Packages</h5>
        <table id="estimatedBillPackageTable" class="table">
          <thead>
            <tr>
              <th class="col-xs-6">Package</th>
              <th class="col-xs-2">Quantity</th>
              <th class="col-xs-2">Unit Price</th>
              <th class="col-xs-2">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of estimatedBill?.PackageItems">
              <td class="estimatedBillRightBorder col-xs-6">{{ item.Name }}</td>
              <td class="estimatedBillRightBorder col-xs-2">
                {{ item.Quantity }}
              </td>
              <td class="estimatedBillRightBorder col-xs-2">
                {{ item.ListPrice | currency }}
                {{
                  item.DiscountPercent > 0
                    ? "(" + item.DiscountPercent + "% off)"
                    : ""
                }}
              </td>
              <td class="col-xs-2">{{ item.Total | currency }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td
                colspan="4"
                *ngIf="
                  !estimatedBill?.PackageItems ||
                  estimatedBill?.PackageItems?.length === 0
                "
                class="text-center"
              >
                <span>No results returned</span>
              </td>
            </tr>
            <tr
              class="table-footer"
              *ngIf="
                estimatedBill &&
                estimatedBill?.PackageItems &&
                estimatedBill?.PackageItems?.length > 0
              "
            >
              <td class="font-weight-bold" colspan="3">Package Subtotal</td>
              <td class="font-weight-bold total">
                {{ estimatedBill?.PackageTotal | currency }}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="row ml-1">
      <div class="col-md-12 p-0">
        <h5>Add-ons</h5>
        <table id="estimatedBillAddOnTable" class="table">
          <thead>
            <tr>
              <th class="col-xs-6">Add-on</th>
              <th class="col-xs-2">Quantity</th>
              <th class="col-xs-2">Unit Price</th>
              <th class="col-xs-2">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of estimatedBill?.AddOnItems">
              <td class="estimatedBillRightBorder col-xs-6">{{ item.Name }}</td>
              <td class="estimatedBillRightBorder col-xs-2">
                {{ item.Quantity }}
              </td>
              <td class="estimatedBillRightBorder col-xs-2">
                {{ item.ListPrice | currency }}
                {{
                  item.DiscountPercent > 0
                    ? "(" + item.DiscountPercent + "% off)"
                    : ""
                }}
              </td>
              <td class="col-xs-2">{{ item.Total | currency }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td
                colspan="4"
                *ngIf="
                  !estimatedBill?.AddOnItems ||
                  estimatedBill?.AddOnItems.length === 0
                "
                class="text-center"
              >
                <span>No results returned</span>
              </td>
            </tr>
            <tr
              class="table-footer"
              *ngIf="estimatedBill?.AddOnItems.length > 0"
            >
              <td class="font-weight-bold" colspan="3">Add-on Subtotal</td>
              <td class="font-weight-bold total">
                {{ estimatedBill?.AddOnTotal | currency }}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="row ml-1">
      <div class="col-md-12 p-0">
        <h5>Licenses</h5>
        <table id="estimatedBillLicenseTable" class="table">
          <thead>
            <tr>
              <th class="col-xs-6">License</th>
              <th class="col-xs-2">Quantity</th>
              <th class="col-xs-2">Unit Price</th>
              <th class="col-xs-2">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of estimatedBill?.LicenseItems">
              <td class="estimatedBillRightBorder col-xs-6">{{ item.Name }}</td>
              <td class="estimatedBillRightBorder col-xs-2">
                {{ item.Quantity }}
              </td>
              <td class="estimatedBillRightBorder col-xs-2">
                {{ item.ListPrice | currency }}
                {{
                  item.DiscountPercent > 0
                    ? "(" + item.DiscountPercent + "% off)"
                    : ""
                }}
              </td>
              <td class="col-xs-2">{{ item.Total | currency }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td
                colspan="4"
                *ngIf="
                  !estimatedBill?.LicenseItems ||
                  estimatedBill?.LicenseItems.length === 0
                "
                class="text-center"
              >
                <span>No results returned</span>
              </td>
            </tr>
            <tr
              class="table-footer"
              *ngIf="estimatedBill?.LicenseItems.length > 0"
            >
              <td class="font-weight-bold" colspan="3">License Subtotal</td>
              <td class="font-weight-bold total">
                {{ estimatedBill?.LicenseTotal | currency }}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</div>
