import { Injectable } from "@angular/core";
import { Dropdown } from "src/app/core/models/Dropdown";

@Injectable({
  providedIn: "root",
})
export class ConstantsService {
  accountTypes: Dropdown[];
  constructor() {
    this.accountTypes = [
      { label: "Checking", value: "Checking" },
      { label: "Savings", value: "Savings" },
    ];
  }
  public XIDHelp =
    "<span data-testid='tooltipMsg'>If you have your own client tracking/ID system at your organization, you can enter a unique identifier for your client (or user) here. Otherwise, leave it blank. XIDs appear on certain reports and invoices if they are set.</span>";
  public ConstOrder_XID = "-1";
  public ConstOrder_Source = "AppHub";

  public FolderRenameSuccess = "The folder was updated successfully.";
  public billingInfoConst =
    "The billing information will be applied to any Rightworks purchases you make on this account.";

  public packageAssignRemoveTooltip = `
    <p><b>Assigning and Removing Packages from Users</b></p>
    <p>Assigning a package to a user will automatically release the currently assigned package back to the organization. Applications will be updated accordingly based on what the user is entitled to with their new package.</p>
    <p>Removing a package from a user will also release the package back to the organization. If the original package is no longer needed, the quantity can be decreased in the Packages & Add-ons page.</p>
    `;

  public OrgConvertStart = "Organization conversion to folder started.";

  public getRolesTooltip(hasAccountOwner: boolean): string {
    let roles = "";
    if (hasAccountOwner) {
      roles += `<p data-testid='tooltipMsg1'>
         <b>Account Owners </b> cannot be created through "Add User". If you wish to appoint a new account owner, you will need to change their role on that user's profile.
         </p>`;
    }
    roles += `<p data-testid='tooltipMsg2' />
      <b>Account Admins</b> can perform most actions, including creating new users, managing packages, and managing licenses.
      
      <b>Billing Admins</b> can manage packages, licenses, and update billing information.
      
      <b>Support</b> can view users, perform password resets, unlock accounts, and resend welcome emails.
      
      <b>Users</b> are regular accounts that can log in to their hosted desktops and perform some basic self-management actions.
      
      <b>User (no catalog)</b> accounts cannot get to the App Catalog and won't be able to deploy their own apps.
      <p />`;

    return roles;
  }

  public billingSeparationAddBillingInfoTooltip =
    "Your Accountant has indicated that you will be paying for your own Rightworks products by declaring you the Account Owner. Please enter your billing information before continuing.";
  public billingSeparationTooltip =
    '<span data-testid="tooltipMsg">This action cannot be performed until the <br/> SubOrg completes the separation process.</span>';
  public adjustInventoryNoBillingInfoMessage =
    "To add or drop packages, you need a payment method on file with Rightworks. To set one up, click Continue.";
  public purchaseQboSubscriptionNoBillingInfoMessage =
    "To purchase a QBO subscription, you need a payment method on file with Rightworks. To set one up, click Continue.";
  public cancelCreateBillingInfoAddOnsToast =
    "You must complete billing information in order to upgrade to Add-on packages.";
  public cancelCreateBillingInfoPackagesToast =
    "You must complete billing information in order to purchase more packages.";

  public http500InternalServerErrorMessage =
    "We have encountered an error processing your request.";

  public startSessionAppName = "AppHub Start Session";
}
