import { Component, OnInit } from "@angular/core";
import { LoggedInInfoService } from "../../../../services/loggedInInfo/logged-in-info.service";
import { NavigationService } from "../../../../services/navigation/navigation.service";

@Component({
  selector: "app-rn-sidebar",
  templateUrl: "./rn-sidebar.component.html",
  styleUrls: ["./rn-sidebar.component.scss"],
})
export class RnSidebarComponent implements OnInit {
  public showRNSupportItems: boolean;

  constructor(
    private navigationService: NavigationService,
    private loggedInInfoService: LoggedInInfoService,
  ) {}

  ngOnInit(): void {
    this.loggedInInfoService.LoggedInuserOrgConfig.subscribe((c) => {
      if (c) {
        this.showRNSupportItems = !c.DisallowRNSupport;
      }
    });
  }

  public launchDownloadSetupWizard(): void {
    this.navigationService.showDownloadSetupDialog(false);
  }
}
