<div [formGroup]="frmComponent" class="select-div + {{ userClass }}">
  <div *ngIf="showIcon" class="input-icons">
    <i class="fa fa-user icon"></i>
    <p-dropdown
      [id]="componentId"
      [name]="componentId"
      [attr.data-testid]="componentId"
      [formControlName]="componentId"
      [options]="itemsArray"
      (onChange)="selectionChanged()"
      [placeholder]="defaultText"
      [styleClass]="cssClasses"
      [required]="required"
    >
    </p-dropdown>
  </div>
  <div *ngIf="!showIcon">
    <p-dropdown
      [id]="componentId"
      [appendTo]="overlayClass ? 'body' : null"
      [name]="componentId"
      [attr.data-testid]="componentId"
      [formControlName]="componentId"
      [options]="itemsArray"
      (onChange)="selectionChanged()"
      [placeholder]="defaultText"
      [styleClass]="cssClasses"
      dropdownIcon="fa fa-caret-down"
      [required]="required"
      (onShow)="onDropdownShow()"
    >
    </p-dropdown>
  </div>
  <div class="width-15px spinner-div" *ngIf="loading">
    <app-icon
      source="rn-circle-notch"
      imgClass="loading-spinner gray-icon "
    ></app-icon>
  </div>
  <div *ngIf="component.touched">
    <div class="help-block" *ngIf="component.errors?.required">
      Required field
    </div>
  </div>
</div>
