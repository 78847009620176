/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnUserPackagePoolsVM } from "../models/./userPackagePoolsVM-d";
import { RnUserProfileConnectPointsVM } from "../models/./userProfileConnectPointsVM-d";

export class RnUsersProfileVM {
  UserID?: number;
  FirstName?: string | null;
  LastName?: string | null;
  EmailAddress?: string | null;
  PhoneNumber?: string | null;
  OrganizationID?: number;
  UserName?: string | null;
  OrganizationName?: string | null;
  UserState?: string | null;
  UserType?: string | null;
  UserTypeID?: number;
  AreaCode?: string | null;
  CountryCode?: string | null;
  SupportPIN?: string | null;
  ProfileCreated?: boolean;
  ExternalID?: string | null;
  IdThatPaysForThisUser?: number;
  OUName?: string | null;
  ProfileFolderPath?: string | null;
  HomeFolderPath?: string | null;
  TerminalServicesGroupName?: string | null;
  DataCenterName?: string | null;
  AppPoolID?: number;
  AppPoolName?: string | null;
  activePackages?: Array<RnUserPackagePoolsVM> | null;
  ConnectPointData?: Array<RnUserProfileConnectPointsVM> | null;
  EffStartDate?: string;
  EffEndDate?: string;
  CreatedDate?: string;
  AssignedPackage?: string | null;
  AssignedAddOns?: Array<string> | null;
  DirectBilledPackages?: Array<string> | null;
  ShowSetupWizardDownload?: boolean;
  MfaEnabled?: string | null;
  MFAAllowed?: boolean;
  HasExternalHosting?: boolean;
  RemoteAppEnabled?: boolean;
  HideSetupWizardLinks?: boolean;
  CanCloseAllApps?: boolean;
}
