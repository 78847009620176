import { HttpResponse } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { Observable, Subject, Subscription } from "rxjs";
import { OrganizationCatalogInfoVM } from "src/app/shared/services/rnapi2-service/models/OrganizationCatalogInfoVM";
import { CopyActivationLinkConfiguration } from "../../../../core/models/copyActivationLinkConfiguration";
import { DataRetrievalParameters } from "../../../../core/models/data-retrieval-parameters";
import { GenericPropertyPayload } from "../../../../core/models/signalr/generic-property-payload";
import { OrgUserCreationPayload } from "../../../../core/models/signalr/org-user-creation-payload";
import { SignalrPollingBaseMessage } from "../../../../core/models/signalr/signalr-polling-base-message";
import { UserSearchPayload } from "../../../../feature-modules/organization/organization-details/organization-users/store/store.service";
import { NewUserAssignmentInfo } from "../../../../feature-modules/welcomewizard/new-user-assignment-info";
import { QuantityCounts } from "../../../../feature-modules/welcomewizard/quantity-counts";
import { UserPackageAssignment } from "../../../../feature-modules/welcomewizard/user-package-assignment";
import { SearchControlConfiguration } from "../../../../shared/components/rn-business-components/search-control/configuration/search-control-configuration";
import { SearchControlComponent } from "../../../../shared/components/rn-business-components/search-control/search-control.component";
import { GridCheckboxColumnConfiguration } from "../../../../shared/components/third-party-wrappers/grid/configuration/grid-checkbox-column-configuration";
import { GridColumnConfiguration } from "../../../../shared/components/third-party-wrappers/grid/configuration/grid-column-configuration";
import { GridConfiguration } from "../../../../shared/components/third-party-wrappers/grid/configuration/grid-configuration";
import { GridHtmlColumnConfiguration } from "../../../../shared/components/third-party-wrappers/grid/configuration/grid-html-column-configuration";
import { GridMultiValueColumnConfiguration } from "../../../../shared/components/third-party-wrappers/grid/configuration/grid-multi-value-custom-configuration";
import { RnDialogService } from "../../../../shared/services/dialog/rndialog.service";
import { LoggedInInfoService } from "../../../../shared/services/loggedInInfo/logged-in-info.service";
import {
  LookupService,
  OrganizationService,
  UserService,
} from "../../../../shared/services/rnapi2-service/apis/api";
import {
  RnCommonId,
  RnCommonIdMulti,
  RnCommonMultipleId,
  RnCommonSearch,
  RnOrganizationConfigurationVM,
  RnOrganizationsProfileVM,
  RnOrganizationWLPContactInformationVM,
  RnPackageUsersAction,
  RnUserCreateFull,
  RnUserPackageAssign,
  RnUserPackagePoolAction,
  RnUsersVM,
  RnUserTypesVM,
} from "../../../../shared/services/rnapi2-service/models/models";
import { SelectionService } from "../../../../shared/services/selection/selection.service";
import { Guid } from "../../../../shared/utilities/guid/guid.utilities";
import { NotificationDialogService } from "../../../services/notificationDialog/notification-dialog.service";
import { SignalrPollingService } from "../../../services/signalr-polling/signalr-polling.service";
import {
  GridActionButton,
  GridActions,
} from "../../third-party-wrappers/grid/configuration/grid-action-button";
import { GridActionButtonColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-action-button-column-configuration";
import { GenericDialogConfiguration } from "../generic-dialog/generic-dialog-configuration";
import { PackageCardData } from "../package-card/package-card-data";
import { UserAddInProgress } from "./user-add-in-progress";
import { AuthService } from "@rn-platform/frontend-shared-feature-identity-auth";

@Component({
  selector: "app-users-management",
  templateUrl: "./users-management.component.html",
  styleUrls: ["./users-management.component.scss"],
})
export class UsersManagementComponent implements OnInit, OnDestroy {
  @Input() OrganizationID: number;
  @Input() IsUsingWelcomeWizard = false;
  @Input() UserPackageAssignments: NewUserAssignmentInfo[] = [];
  @Input() UsersAdded: Subject<NewUserAssignmentInfo[]>;
  @Input() set OwnerActions(actions: QuantityCounts[]) {
    this.ownerActions = actions;
    this.trueUpOwnerPackage();
  }
  @Input() set Packages(packages: PackageCardData[]) {
    this.packages = packages?.filter((p) => p.HasTerminalServices);
  }
  @Input() PackageAssignments: Array<NewUserAssignmentInfo> = [];
  @Input() orgProfile: RnOrganizationsProfileVM;
  @Input() orgConfig: RnOrganizationConfigurationVM;
  @Input() orgWlpContactInfo: RnOrganizationWLPContactInformationVM;

  @Output() AddUserPackageAssignment =
    new EventEmitter<NewUserAssignmentInfo>();
  @Output() TriggerAddUserPackageAssignment = new EventEmitter<boolean>();
  @Output() NewUserDeleted = new EventEmitter<Array<string>>();
  @Output() ComponentLoaded = new EventEmitter<boolean>();

  @ViewChild("userSearchControl") search: SearchControlComponent;
  clearSearch: Subject<void> = new Subject<void>();
  clearSelection: Subject<void> = new Subject<void>();
  organizationUsers: RnUsersVM[];
  orgId: string;
  userListGridConfiguration: GridConfiguration = new GridConfiguration();
  topLevelOrgId: number;
  selectedUsers: RnUsersVM[];
  searchControlConfiguration: SearchControlConfiguration;
  searchText = "";
  keepOldPackages = true;
  ownerActions: QuantityCounts[];
  userActions: QuantityCounts[] = [];
  workInProgress = false;
  packages: PackageCardData[] = [];
  sendActivationEmailDialogRef: DynamicDialogRef;
  userAddsInProgress: Array<UserAddInProgress> = [];
  subscription!: Subscription;
  sendingResetEmails = false;
  resendingActivationEmails = false;
  generatingReport = false;
  userTypes: RnUserTypesVM[];
  packageData: PackageCardData[];
  addOns: PackageCardData[];
  activationLink: string;
  addSymbol = true;
  isAddUserVisible;
  resetPageNumber: boolean;

  private initialState: UserSearchPayload;
  private UserPackage;
  private UserLicense;
  private UserAddons;
  private isLoadingUsers = false;
  private lastUrl = "";
  private signalRHandlerID: Guid;
  private subscriptions = [];
  public technical_support_phone: string;

  constructor(
    private organizationService: OrganizationService,
    private userService: UserService,
    private loggedInInfoService: LoggedInInfoService,
    private activatedRoute: ActivatedRoute,
    private rnDialogService: RnDialogService,
    private router: Router,
    private selectionService: SelectionService,
    private lookupService: LookupService,
    private notificationDialogService: NotificationDialogService,
    private signalrPollingService: SignalrPollingService,
    private authService: AuthService,
  ) {
    this.isAddUserVisible =
      this.loggedInInfoService.loggedInUserHasRights(["ADDNWUSERS"]) &&
      !this.loggedInInfoService.GetLoggedInUserOrgConfig().IsInternal;

    this.selectedUsers = [];
    this.initialState = {
      guid: "",
      rnCommonSearch: {
        Id: this.orgId,
        pageSize: 10,
        pageNumber: 0,
        OrderByText: "orderByFullName",
        isDescendingOrder: false,
        search: this.searchText || "",
      },
    };

    const navigationSubscription = router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.lastUrl != e.url) {
          this.lastUrl = e.url;
          this.performSearch();
        }
      }
    });
    this.subscriptions.push(navigationSubscription);
    this.signalRHandlerID = this.signalrPollingService.AddMessageHandler(
      (message) => {
        const notification = JSON.parse(message) as SignalrPollingBaseMessage;
        if (notification && notification.Payload) {
          if (
            notification.Payload.AreaDataType.toUpperCase() ===
              "GENERIC_PROPERTY_PAYLOAD" &&
            notification.Payload.Area.toUpperCase() === "ORG"
          ) {
            const data = JSON.parse(
              notification.Payload.AreaData,
            ) as GenericPropertyPayload;
            if (data.Properties && data.Properties.length > 0) {
              const mfaItem = data.Properties.find(
                (p) => p.PropertyName.toLowerCase() === "mfa_status",
              );
              const userIdItem = data.Properties.find(
                (p) => p.PropertyName.toLowerCase() === "user_id",
              );
              if (mfaItem) {
                const enabledMfa =
                  mfaItem.PropertyValue.toLowerCase() === "enabled";
                if (enabledMfa) {
                  const user = this.organizationUsers.find(
                    (u) => u.UserID.toString() === userIdItem.PropertyValue,
                  );
                  user.MfaEnabled = "Enabled";
                }
              }
            }
          } else if (
            notification.Payload.AreaDataType.toUpperCase() ===
            "ORG_USERCREATION_PAYLOAD"
          ) {
            const data = JSON.parse(
              notification.Payload.AreaData,
            ) as OrgUserCreationPayload;
            if (data && data.Finished) {
              this.getUserStatus(data.Email);
            }
          } else if (
            notification.Payload.AreaDataType.toUpperCase() ===
            "USER_DELETE_PAYLOAD"
          ) {
            this.loadUserList();
          }
        }
      },
    );
  }

  ngOnInit(): void {
    this.topLevelOrgId =
      this.OrganizationID ?? this.activatedRoute.snapshot.params.id;
    if (this.loggedInInfoService.GetWLPConfig) {
      const wlpConfigData = this.loggedInInfoService?.GetWLPConfig()?.data;
      if (wlpConfigData) {
        this.technical_support_phone =
          Object.values(wlpConfigData)[
            Object.keys(wlpConfigData).indexOf("technical_support_phone_number")
          ];
      }
    }

    if (this.UsersAdded) {
      this.UsersAdded.subscribe((values) => {
        // rebuild userActions
        this.userActions = [];
        for (const user of values) {
          const pIndex = this.userActions.findIndex(
            (p) => p.PackageID == user.Package.PackageID,
          );
          if (pIndex >= 0) {
            this.userActions[pIndex].Quantity++;
          } else {
            this.userActions.push({
              PackageID: user.Package.PackageID,
              Quantity: 1,
            });
          }

          for (const addon of user.Addons) {
            const aIndex = this.userActions.findIndex(
              (a) => a.PackageID == addon.PackageID,
            );
            if (aIndex >= 0) {
              this.userActions[pIndex].Quantity++;
            } else {
              this.userActions.push({
                PackageID: addon.PackageID,
                Quantity: 1,
              });
            }
          }
        }
      });
    }

    if (this.topLevelOrgId) {
      this.subscriptions.push(
        this.loadOrganizationPackageData().subscribe((r) => {
          if (r.body.Success) {
            this.packageData = [];
            r.body.data.CatalogItems.forEach((p) => {
              const pkg = new PackageCardData();
              pkg.AssignFromRnCatalogItemVM(p);
              if (p.PackageUIType === "package") {
                this.packageData.push(pkg);
              }
            });

            const addOnsList = [];
            this.packageData.forEach((p) => {
              if (p.PackageUIType === "addon" && p.PackageTypeID !== 6) {
                addOnsList.push(p);
              }
            });
            this.addOns = addOnsList;
          }
        }),
      );
    }

    // Leaving this commented out if we ever decide to bring back the storing of state info

    //const subscribtionQueryParams = this.activatedRoute.queryParams.subscribe(params => {
    //  this.currentSearchGuid = params.guid ?? "";
    //});
    //this.subscriptions.push(subscribtionQueryParams);

    //const storeSearch = this.store.getSearch(this.currentSearchGuid).subscribe(result => {
    //  if (result) {
    //    if (this.currentSearchGuid === result.guid) {
    //      this.initialState = result;
    //    }
    //    else {
    //      this.initialState = this.store.initialState;
    //    }
    //    this.searchText = this.initialState.rnCommonSearch.search;
    //    this.navigationEnd();
    //  }
    //});
    //this.subscriptions.push(storeSearch);

    const mapSubscription = this.activatedRoute.paramMap.subscribe((p) => {
      const queryMapSub = this.activatedRoute.queryParamMap.subscribe((m) => {
        this.orgId = m.has("subOrgId")
          ? m.get("subOrgId")
          : (this.OrganizationID ?? this.activatedRoute.snapshot.params.id);
        this.initialState.rnCommonSearch.Id = this.orgId;

        this.subscriptions.push(
          this.lookupService
            .apiV2LookupUsertypesGet(
              Number(this.initialState.rnCommonSearch.Id),
              -1,
              false,
            )
            .subscribe((response) => {
              this.userTypes = response.data.filter(
                (r) => r.Name != "Account Owner",
              );
            }),
        );
      });
      this.subscriptions.push(queryMapSub);
    });
    this.subscriptions.push(mapSubscription);

    const truthy = "<i class='fa fa-check-circle'></i>";
    const falsey = "<i class='fa fa-times-circle'></i>";

    this.userListGridConfiguration.FirstRow = 0;
    this.userListGridConfiguration.getClassForRow = (rowData: RnUsersVM) => {
      if (
        rowData.UserState.indexOf("Processing") >= 0 &&
        rowData.UserStateName == "Active"
      ) {
        return "successTrans";
      } else if (
        rowData.UserStateName == "Failed" ||
        rowData.UserStateName == "Failed - Fatal"
      ) {
        return "failedToAdd";
      }
    };
    // User List Grid Configuration for retrieving data
    this.userListGridConfiguration.setUrlData = (
      params: DataRetrievalParameters,
    ) => {
      this.initialState.rnCommonSearch.pageNumber = params.PageNumber;
      this.initialState.rnCommonSearch.pageSize = params.PageSize;
      this.initialState.rnCommonSearch.isDescendingOrder =
        params.IsDescendingOrder;
      this.initialState.rnCommonSearch.OrderByText = params.SortOrder
        ? params.SortOrder
        : "orderByFullName";
      this.selectedUsers.length = 0; //resetting the selected user on change page or selection order
      this.performSearch();
    };

    // column configuration
    this.userListGridConfiguration.ColumnConfiguration = [];
    this.userListGridConfiguration.CountHeaderItemsName = "Users";
    this.userListGridConfiguration.ColumnConfiguration.push(
      new GridCheckboxColumnConfiguration("checkBox", "", "checkboxCol"),
    );
    const username = new GridMultiValueColumnConfiguration(
      ["FullName", "Email"],
      "Name",
      ["userslist-multi-value-top", "userslist-multi-value-bottom"],
      "orderByFullName",
      !this.IsUsingWelcomeWizard,
      true,
    );
    if (!this.IsUsingWelcomeWizard) {
      username.GetHref = (rowData: { UserID: string }) => {
        return "/user/" + rowData.UserID;
      };
    }
    this.userListGridConfiguration.GridClass = "tableHeadingColorClass";
    this.userListGridConfiguration.ColumnConfiguration.push(username);
    if (this.loggedInInfoService.loggedInUserHasRights(["SNDUSRWLCM"])) {
      this.activationLink = "Copy Activation Link";
    }
    const activated = new GridHtmlColumnConfiguration(
      "Activated",
      "Activated",
      null,
      "orderByActivated",
      truthy,
      falsey,
      this.activationLink,
    );
    activated.GetHtmlDataForColumn = (dataRow: any) => {
      if (dataRow.UserStateName == "Processing") {
        return `<div class="dspy-flex"><div class="m-bt-auto m-l-neg8 addUserSpinner pull-left">
                    <i class="fa-solid fa-circle-notch fa-spin faMedium fa-fw"></i>
         </div>
         <div>
          This user is being added to your account. <br /> This may take some time to set them up
            in the system.You may continue working while this happens in the background.
         </div></div>`;
      } else if (dataRow.UserStateName == "Failed") {
        return `<div class="ws-normal">
          <b>User could not be added to account. Please try again.</b><br /> Select Add User if you want us to
          try adding the user again, or select Cancel if you want to end this action.
         </div></div>`;
      } else if (dataRow.UserStateName == "Failed - Fatal") {
        return `<div class="ws-normal">
          <b>Sorry, we're not able to add the user at this time. Please contact ${this.technical_support_phone}. 
         </div>`;
      } else {
        return dataRow.SupportPIN ? truthy : falsey;
      }
    };
    this.userListGridConfiguration.ColumnConfiguration.push(activated);
    const multifactor = new GridHtmlColumnConfiguration(
      "MFA",
      "Security (MFA)",
      null,
      null,
    );
    multifactor.GetHtmlDataForColumn = (dataRow: any) => {
      if (dataRow.UserStateName === "Processing") {
        return "";
      } else {
        return dataRow.MfaEnabled ? "Enabled" : "Disabled";
      }
    };
    this.userListGridConfiguration.ColumnConfiguration.push(multifactor);

    const assignedPackage = new GridHtmlColumnConfiguration(
      "AssignedPackage",
      "Assigned Package",
      "users-list-package",
      "orderByAssignedPackage",
    );
    assignedPackage.GetHtmlDataForColumn = (dataRow: RnUsersVM) => {
      return dataRow.AssignedPackage
        ? "<span>" + dataRow.AssignedPackage + "</span>"
        : "None";
    };
    this.userListGridConfiguration.ColumnConfiguration.push(assignedPackage);

    const buttonsConfig: Array<GridActionButton> =
      new Array<GridActionButton>();
    const DELETUSERS = this.loggedInInfoService.loggedInUserHasRights([
      "DELETUSERS",
    ]);
    const ADDNWUSERS = this.loggedInInfoService.loggedInUserHasRights([
      "ADDNWUSERS",
    ]);
    if (DELETUSERS || ADDNWUSERS) {
      const failedActions = new GridActionButton();
      failedActions.property = "Failed";
      failedActions.buttonsText = [];
      if (ADDNWUSERS) {
        const addUserAction = new GridActions();
        addUserAction.id = 0;
        addUserAction.text = "Add User";
        addUserAction.type = "Retry";
        failedActions.buttonsText.push(addUserAction);
      }
      if (DELETUSERS) {
        const cancelAction = new GridActions();
        cancelAction.id = 1;
        cancelAction.class = "rn-text-link closeErroredUser";
        cancelAction.text = "Cancel";
        cancelAction.type = "Cancel";
        failedActions.buttonsText.push(cancelAction);
      }
      buttonsConfig.push(failedActions);

      if (DELETUSERS) {
        const fatalActions: GridActionButton = {
          property: "Failed - Fatal",
          buttonsText: [
            {
              id: 0,
              class: "rn-text-link closeErroredUser",
              text: "Close",
              type: "Cancel",
            },
          ],
        };
        buttonsConfig.push(fatalActions);
      }
    }

    const roleColumn = new GridActionButtonColumnConfiguration(
      "Role",
      "Role",
      "search-user-results-role",
      "orderByUserTypeName",
      buttonsConfig,
    );
    roleColumn.predicate = (dataRow: RnUsersVM) => {
      return dataRow.UserStateName != "Active";
    };
    this.userListGridConfiguration.ColumnConfiguration.push(roleColumn);

    this.userListGridConfiguration.getColSpanForCell = (
      col: GridColumnConfiguration,
      rowData: any,
    ) => {
      if (col.FieldName == "Activated" && rowData.UserStateName != "Active") {
        return "3";
      } else {
        return "1";
      }
    };

    this.userListGridConfiguration.getClassForCell = (
      col: GridColumnConfiguration,
      rowData: any,
    ) => {
      let additionalClass = "";
      if (
        col.FieldName != "FullName" &&
        col.FieldName != "Activated" &&
        col.FieldName != "Role" &&
        rowData.UserStateName != "Active"
      ) {
        additionalClass += " userProcessingDisplay";
      }
      return col.Class + " truncate" + additionalClass;
    };

    // search bar configuration
    this.searchControlConfiguration = new SearchControlConfiguration();
    this.searchControlConfiguration.EditFieldPlaceholderText = "Search Users";
    this.searchControlConfiguration.ButtonClass =
      "internal-landing-search-button";
    this.searchControlConfiguration.EditClass = "org-user-search-edit";
    this.searchControlConfiguration.ContainerClass =
      "col-xs-12 col-sm-12 col-md-12 col-lg-12";
    this.searchControlConfiguration.ShowMagnifyingGlassIcon = true;
    this.searchControlConfiguration.MagnifyingGlassClass = "userSearch";

    // clear search when the top level org changes
    this.subscriptions.push(
      this.selectionService.SelectedTopLevelOrg.subscribe((o) => {
        this.clearSearch.next();
      }),
    );
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem("removedUserIDs");
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.userAddsInProgress.forEach((userAdd) =>
      clearInterval(userAdd.TimerID),
    );

    if (this.signalRHandlerID) {
      this.signalrPollingService.RemoveMessageHandler(this.signalRHandlerID);
    }
  }

  searchOccurred(searchText: string): void {
    // reset selected users on search as navigation changes
    this.clearSelection.next();

    this.searchText = searchText;
    this.performSearch();
  }

  performSearch(): void {
    this.initialState.rnCommonSearch.search = this.searchText;
    if (
      this.initialState.rnCommonSearch.Id &&
      this.initialState.rnCommonSearch.Id !== ""
    ) {
      this.loadUserList();
    }
  }

  trueUpOwnerPackage(): void {
    if (this.organizationUsers) {
      const ao = this.organizationUsers.find((u) => u.Role === "Account Owner");
      // Account Owner might not be in the current user list
      if (ao) {
        const found = this.ownerActions.filter(
          (a) =>
            a.Quantity > 0 &&
            this.packages?.find((p) => p.PackageID === a.PackageID),
        );
        if (found?.length > 0) {
          const assignedPackageId = found[0]?.PackageID;
          ao.AssignedPackage = this.packages.find(
            (p) => p.PackageID === assignedPackageId,
          ).PackageName;
        } else {
          // no package
          ao.AssignedPackage = "";
        }
      }
    }
  }

  loadUserList(): void {
    //if (!this.isLoadingUsers) {
    this.isLoadingUsers = true;
    this.ComponentLoaded.emit(true);
    if (
      this.initialState.rnCommonSearch.Id &&
      this.initialState.rnCommonSearch.Id !== ""
    ) {
      this.ComponentLoaded.emit(true);
      this.initialState.rnCommonSearch.pageNumber = this.resetPageNumber
        ? 0
        : this.initialState.rnCommonSearch.pageNumber;
      const postSub = this.organizationService
        .apiV2OrganizationsUsersPost(
          this.initialState.rnCommonSearch,
          "response",
        )
        .subscribe((response) => {
          this.isLoadingUsers = false;
          this.ComponentLoaded.emit(false);
          this.organizationUsers = response.body.data.Results;
          this.selectionService.updateUserCount(response.body?.data?.TotalNumberRecords);
          this.userListGridConfiguration.TotalRecords =
            response.body.data.TotalNumberRecords;
          this.userListGridConfiguration.ShowCountHeader = false;
          if (this.ownerActions && this.ownerActions.length > 0) {
            this.trueUpOwnerPackage();
          }
          this.userListGridConfiguration.GridData = this.organizationUsers;
          this.ComponentLoaded.emit(false);
          // configure in-progress users
          this.organizationUsers
            .filter((u) => u.UserStateName == "Processing")
            .forEach((user) => {
              if (
                this.userAddsInProgress.some((u) => u.UserID == user.UserID)
              ) {
                // already there
              } else {
                const userAdd: UserAddInProgress = {
                  UserID: user.UserID,
                  Email: user.Email,
                };
                this.userAddsInProgress.push(userAdd);
              }
            });
          // This only applies to the WW: Find each user that is pending or recently added and find the name of the package they will have.
          if (this.PackageAssignments?.length > 0) {
            this.organizationUsers.forEach((user) => {
              if (!user.AssignedPackage) {
                // find package - if they have one
                const pkg = this.packages.find(
                  (p) =>
                    this.PackageAssignments.find((a) => a.UserID == user.UserID)
                      ?.Package.PackageID == p.PackageID,
                )?.PackageName;
                user.AssignedPackage = pkg ? pkg : user.AssignedPackage;
              }
            });
          }
          const firstRow =
            this.initialState.rnCommonSearch.pageNumber *
            this.initialState.rnCommonSearch.pageSize;
          this.userListGridConfiguration.loadingDataCompleted(
            this.organizationUsers,
            response.body.data.TotalNumberRecords,
            firstRow,
            this.initialState.rnCommonSearch.pageNumber,
          );
        });
      this.subscriptions.push(postSub);
    }
    this.resetPageNumber = false;
    //}
  }

  private getUserStatus(email: string): void {
    const payload: RnCommonSearch = {
      AffectedOrganizationId: Number(this.orgId),
      search: email,
    };
    this.userService
      .apiV2UsersGetadduserstatePost(payload)
      .subscribe((response) => {
        const msg = response.data;
        if (msg.Status.toLowerCase() != "processing") {
          if (msg.Status === "Fatal") {
            msg.Status = "Failed - Fatal";
          }
          this.updateUserStatus(msg);
        }
      });
  }

  private updateUserStatus(message: any): void {
    const userInProg = this.userAddsInProgress.find(
      (u) => u.UserID == message.UserID,
    );
    clearInterval(userInProg.TimerID);
    const user = this.organizationUsers.find((u) => u.UserID == message.UserID);
    user.UserStateName = message.Status;
    user.MfaEnabled = message.MfaEnabled;
  }

  actionClickHandler(event) {
    switch (event.type) {
      case "Retry":
        {
          const retryUser = this.organizationUsers.find((u) => {
            return u.UserID === event.value.UserID;
          });
          if (retryUser) {
            retryUser.UserState = "Processing Current Item.";
            retryUser.UserStateName = "Processing";
          }
          const retryPayload: RnCommonId = {
            AffectedOrganizationId: Number(this.orgId),
            Id: event.value.UserID,
          };
          const userRetry: UserAddInProgress = {
            UserID: event.value.UserID,
            Email: event.value.Email,
          };
          this.userAddsInProgress.push(userRetry);
          this.userService.apiV2UsersRetryusercreatePost(retryPayload);
        }
        break;
      case "Cancel":
        {
          // close the user
          const cancelPayload: RnCommonMultipleId = {
            AffectedOrganizationId: Number(this.orgId),
            Ids: [event.value.UserID],
          };
          this.userService
            .apiV2UsersDeleteusersPost(cancelPayload)
            .subscribe(() => {
              this.organizationUsers.splice(
                this.organizationUsers.findIndex(
                  (u) => u.UserID == event.value.UserID,
                ),
                1,
              );
              if (this.IsUsingWelcomeWizard) {
                // inform the welcome wizard
                this.NewUserDeleted.emit([event.value.UserID]);
              }
            });
        }
        break;
      default:
        break;
    }
  }

  rowSelected(event): void {
    this.selectedUsers = event;
  }

  navigateToUser(event): void {
    this.router.navigate(["/user/" + event.UserID]);
  }

  copyActivationLinkClicked(event): void {
    const copyActivationConfig = new CopyActivationLinkConfiguration();
    copyActivationConfig.userID = event.UserID;
    copyActivationConfig.userFullName = event.FullName;
    copyActivationConfig.organizationID = parseInt(
      this.initialState.rnCommonSearch.Id,
    );
    const dialogRef =
      this.rnDialogService.CopyActivationLinkDialog(copyActivationConfig);
  }

  hasUsersSelected(): boolean {
    return this.selectedUsers?.length > 0;
  }

  // user actions
  createNewUser(): void {
    const dialog = this.rnDialogService.CreateNewUserDialog(
      this.userTypes,
      Number(this.initialState.rnCommonSearch.Id),
      this.orgProfile,
      this.orgConfig,
      this.orgWlpContactInfo,
      this.IsUsingWelcomeWizard,
      this.ownerActions,
      this.userActions,
    );
    dialog.beforeClosed().subscribe((result) => {
      this.workInProgress = false;
      this.TriggerAddUserPackageAssignment.emit(!!result);
      if (result) {
        this.ComponentLoaded.emit(true);

        if (this.IsUsingWelcomeWizard) {
          this.UserPackage = result.UserPackage;
          this.UserLicense = result.UserLicense;
          this.UserAddons = result.UserAddons;
          result = this.separateUserPackageAssignments(result);
        }
        this.userService
          .apiV2UsersCreateuserPost(result)
          .subscribe((response: any) => {
            this.workInProgress = false;
            if (this.IsUsingWelcomeWizard) {
              const userAssignment: NewUserAssignmentInfo = {
                UserID: response.data,
                Package: this.convertPackageToCartCompatableModel(
                  this.UserPackage,
                ),
                License: this.UserLicense,
                Addons: this.convertAddonToCartComptableModel(this.UserAddons),
              };
              this.AddUserPackageAssignment.emit(userAssignment);
            }

            this.loadUserList();
          });
      }
    });
  }

  convertPackageToCartCompatableModel(
    model: RnUserPackageAssign,
  ): UserPackageAssignment {
    const cartModel: UserPackageAssignment = {
      PackageID: model.Package_ID,
      UserID: model.User_ID,
    };
    return cartModel;
  }

  convertAddonToCartComptableModel(
    model: Array<RnUserPackagePoolAction>,
  ): Array<UserPackageAssignment> {
    const cartModel: Array<UserPackageAssignment> =
      new Array<UserPackageAssignment>();
    for (const addon of model) {
      cartModel.push({
        PackageID: addon.ToPackage_ID,
        UserID: addon.Users_ID,
      });
    }
    return cartModel;
  }

  separateUserPackageAssignments(newUser: RnUserCreateFull) {
    newUser.UserPackage = {
      AffectedOrganizationId: Number(this.orgId),
      AffectedUserId: -1,
      PackagePool_ID: -2,
      Package_ID: -1,
      User_ID: -1,
    };
    newUser.UserLicense = {
      AffectedOrganizationId: Number(this.orgId),
      LicenseID: 0,
      FlavorID: 0,
      UserIDs: [],
    };
    newUser.UserAddons = new Array<RnUserPackagePoolAction>();
    return newUser;
  }

  previousPackageAction(): void {
    const canPurchaseApps =
      this.packageData.filter((p) => p.CatalogVisibility === "Unpurchasable")
        ?.length > 0;
    let unpurchasable;
    if (canPurchaseApps) {
      unpurchasable = "Unpurchasable";
    }
    const selectedUsersWithPackage: any[] = this.selectedUsers.filter(
      (user) => user.AssignedPackage != "",
    );
    const selectedUsersWithDirectAddons =
      this.selectedUsers.filter(
        (user) =>
          user.DirectBilledPackages && user.DirectBilledPackages.length > 0,
      )?.length > 0;
    if (selectedUsersWithPackage?.length > 0 && selectedUsersWithDirectAddons) {
      const dialogRef = this.rnDialogService.PreviousPackageActionDialog(
        selectedUsersWithPackage,
        "changePackage",
        unpurchasable,
        true,
      );

      const drCloseSub = dialogRef.onClose.subscribe((s) => {
        // wait to open a new dialog since this one hasn't completed closing yet!
        this.keepOldPackages = s;
      });
      this.subscriptions.push(drCloseSub);
      const drDestroySub = dialogRef.onDestroy.subscribe((s) => {
        if (this.keepOldPackages) {
          // call delete function
          this.deleteUsers();
        }
      });
      this.subscriptions.push(drDestroySub);
    } else {
      // call delete function
      this.deleteUsers();
    }
  }

  deleteUsers(): void {
    const loggedInUser = this.loggedInInfoService.GetLoggedInUser();
    if (!loggedInUser) {
      return;
    }
    // prevent the sneakiness
    const invalidSelection = this.selectedUsers.find(
      (u) =>
        u.Role == "Account Owner" ||
        u.Role == "Intuit PCG Administrator" ||
        u.Role == "Drake Administrator" ||
        loggedInUser.UserID == u.UserID,
    );
    if (invalidSelection) {
      console.log("You cannot delete a(n) " + invalidSelection.Role);
      return;
    }
    if (this.selectedUsers?.length <= 0) {
      console.log("No users selected!");
      return;
    }
    sessionStorage.removeItem("removedUserIDs");
    // finally deletes the user(s)
    const payload: RnPackageUsersAction = {
      Ids: this.selectedUsers.map((user) => user.UserID.toString()),
      AffectedOrganizationId: Number(this.initialState.rnCommonSearch.Id),
      KeepOldPackage: this.keepOldPackages,
    };
    this.ComponentLoaded.emit(true);
    const delPostSub = this.userService
      .apiV2UsersDeleteusersPost(payload)
      .subscribe(() => {
        // need a toast, but for now just log it to the console
        // no need to do a call to the api, just remove users from our GridData
        this.selectedUsers = [];
        this.clearSelection.next();
        if (this.IsUsingWelcomeWizard) {
          // inform the welcome wizard
          this.NewUserDeleted.emit(payload.Ids);
        }
        // no need to assign GridData and TotalRecords above as they will assigned in this.loadUserList
        // handle pagenumber change if select all and remove
        this.resetPageNumber =
          this.organizationUsers.length === payload.Ids.length;
        this.selectionService.updateUserCount(this.organizationUsers.length);
        // users ids selected to delete
        sessionStorage.setItem("removedUserIDs", JSON.stringify(payload.Ids));
      });
    this.subscriptions.push(delPostSub);
  }

  sendPasswordReset(): void {
    this.sendingResetEmails = true;
    const emailAddresses = this.selectedUsers.map((user) => user.Email);
    for (const email of emailAddresses) {
      this.authService.requestPasswordReset(email, false);
    }
    const title = "Password Reset Sent";
    let content = "";
    if (this.selectedUsers?.length > 1) {
      content =
        "We sent password reset emails to the following accounts:<br /><br /><ul>";
      this.selectedUsers.forEach((u) => {
        content += `<li><b>${u.FullName}</b> (${u.Email})</li>`;
      });
      content += "</ul>";
    } else {
      const user = this.selectedUsers[0];
      content += `We sent a password reset email to <b>${user.FullName} </b> at ${user.Email}`;
    }

    const config = new GenericDialogConfiguration();
    config.Title = title;
    config.StyleClass = "confirmation";
    config.DialogHeaderClass = "modal-header no-border";
    config.DialogFooterCancelClass = "right-spacing";
    config.Message = content;
    config.HideCancel = true;
    config.ConfirmButtonStyleClass = "primary";
    config.ConfirmButtonText = "CLOSE";
    config.MessageContainsHTML = true;
    this.notificationDialogService.ShowConfirmation(config);
    // "fake" timer to make it look like it needs to process, but it doesn't really.
    // requestPasswordReset() is a fire-and-forget kind of thing
    setTimeout(() => {
      this.sendingResetEmails = false;
    }, 750);
  }

  sendWelcomeEmail(): void {
    this.resendingActivationEmails = true;
    const payload: RnCommonIdMulti = {
      Ids: this.selectedUsers.map((user) => user.UserID.toString()),
      AffectedOrganizationId: Number(this.initialState.rnCommonSearch.Id),
    };
    const usrRsdWlcmEmlPostSub = this.userService
      .apiV2UsersResendwelcomeemailsPost(payload)
      .subscribe((response) => {
        if (response.Success) {
          const title = "Welcome Email Resent";
          let content = "";
          if (this.selectedUsers?.length > 1) {
            content =
              "We resent welcome emails to the following accounts:<br /><br /><ul>";
            this.selectedUsers.forEach((u) => {
              content += `<li><b>${u.FullName}</b> (${u.Email})</li>`;
            });
            content += "</ul>";
          } else {
            const user = this.selectedUsers[0];
            content += `We resent a welcome email to <b>${user.FullName} </b> at ${user.Email}`;
          }

          const config = new GenericDialogConfiguration();
          config.Title = title;
          config.StyleClass = "confirmation";
          config.DialogHeaderClass = "modal-header no-border";
          config.DialogFooterCancelClass = "right-spacing";
          config.Message = content;
          config.HideCancel = true;
          config.ConfirmButtonStyleClass = "primary";
          config.ConfirmButtonText = "CLOSE";
          config.MessageContainsHTML = true;
          this.notificationDialogService.ShowConfirmation(config);
        }
        this.resendingActivationEmails = false;
      });

    this.subscriptions.push(usrRsdWlcmEmlPostSub);
  }

  generateReport() {
    this.generatingReport = true;
    const payload: RnCommonId = {
      Id: this.orgId,
    };
    this.organizationService
      .apiV2OrganizationsGetusersfororgreportPost(payload)
      .subscribe(
        (result) => {
          const filename = "UserDetails_" + this.orgId.toString() + ".xlsx";
          const contentType = result.type;
          const linkElement = document.createElement("a");
          try {
            const blob = new Blob([result], { type: contentType });
            const url = window.URL.createObjectURL(blob);

            linkElement.setAttribute("href", url);
            linkElement.setAttribute("download", filename);

            const clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            linkElement.dispatchEvent(clickEvent);
          } catch (ex) {
            // console.log(ex);
          }
          this.generatingReport = false;
        },
        () => {
          this.generatingReport = false;
        },
      );
  }

  private loadOrganizationPackageData(): Observable<
    HttpResponse<OrganizationCatalogInfoVM>
  > {
    if (this?.topLevelOrgId) {
      const payload = new RnCommonId();
      payload.Id = this.topLevelOrgId.toString();
      payload.AffectedOrganizationId = this.topLevelOrgId;
      return this.organizationService.apiV2OrganizationsGetcatalogitemsfororgPost(
        payload,
        "response",
      );
    }
  }

  unlockUsers(): void {
    const payload: RnCommonIdMulti = {
      Ids: this.selectedUsers.map((user) => user.UserID.toString()),
      AffectedOrganizationId: Number(this.initialState.rnCommonSearch.Id),
    };
    const usrUnlkUsrsPostSub = this.userService
      .apiV2UsersUnlockusersPost(payload)
      .subscribe((response) => {
        if (response.Success) {
          this.rnDialogService.UnlockUserDialog(this.selectedUsers);
        }
      });

    this.subscriptions.push(usrUnlkUsrsPostSub);
  }
}
