<div class="modal-content dialog-brder">
  <div>
    <button
      type="button"
      class="close pullUpperRight"
      (click)="cancel()"
      aria-hidden="true"
    >
      <span uib-tooltip="Close" tooltipPosition="top" pTooltip="Close">×</span>
    </button>
  </div>
  <h4>Manage Subscriptions</h4>
  <div class="ui-widget">
    <table class="tid-table ui-table">
      <thead class="ui-table-thead">
        <tr>
          <th data-testid="subIDHead">Subscription ID</th>
          <th data-testid="nameHeader" class="itemNameCell">Name</th>
          <th data-testid="typeHeader">Type</th>
        </tr>
      </thead>
      <ng-container *ngFor="let subs of qboSubsData">
        <tr *ngFor="let item of subs.Items; let i = index">
          <td>
            <div data-testid="subID">{{ item.SubscriptionID }}</div>
          </td>
          <td class="itemNameCell">
            <div data-testid="name">{{ item.Name }}</div>
          </td>
          <td>
            <div data-testid="type">{{ item.Type }}</div>
          </td>
          <td>
            <button *ngIf="false" class="button-disp">ADD</button>&nbsp;&nbsp;
            <app-button
              id="removeAddon"
              buttonStyle="primary"
              showProcessing="true"
              [processing]="isProcessing"
              label="REMOVE"
              (clickActionEmitter)="change(item)"
            >
            </app-button
            >&nbsp;&nbsp;
            <!-- <button  class="button-disp" (click) = "change(item)">REMOVE</button>&nbsp;&nbsp; -->
            <button *ngIf="false" class="button-disp">EDIT</button>&nbsp;&nbsp;
          </td>
        </tr>
      </ng-container>
    </table>
  </div>
  <div class="paginator-container">
    <span data-testid="qbo">Rows per page&nbsp;</span>
    <select [(ngModel)]="qboPageSize" (change)="qboPageSizeChanged()">
      <option *ngFor="let option of qboRowsPerPageOptions" [value]="option">
        {{ option }}
      </option>
    </select>
    <span data-testid="qboGridPageButtonContainer" class="float-right">
      1-{{ qboSubsCount }} of {{ qboSubsCount }}&nbsp;&nbsp;&nbsp;&nbsp;
      <button
        class="paginator-nav-button"
        data-testid="qboGridNavButtonLeft"
        [disabled]="true"
        (click)="qboNavToPage(qboCurrentPageNum - 1)"
      >
        <i class="fa fa-chevron-left"></i>
      </button>
      <button
        class="paginator-nav-button"
        [disabled]="true"
        [ngClass]="{ 'active-page-item': true }"
        (click)="qboNavToPage(qboCurrentPageNum)"
        [attr.data-testid]="'gridPageButton_1'"
      >
        {{ 1 }}
      </button>
      <button
        class="paginator-nav-button"
        data-testid="qboGridNavButtonRight"
        [disabled]="true"
        (click)="qboNavToPage(qboCurrentPageNum + 1)"
      >
        <i class="fa fa-chevron-right"></i>
      </button>
    </span>
  </div>
</div>
