import { Injectable } from "@angular/core";
import { PackageCardData } from "../../components/rn-business-components/package-card/package-card-data";

@Injectable({
  providedIn: "root",
})
export class PackagesService {
  public isApplicableDeployQuickBooks(
    selectedPackage: PackageCardData,
    licenses: any,
    addOns: PackageCardData[],
  ): boolean {
    const addOnsRequiringLicense = addOns.filter(
      (x) => x.UsesLicenses === true,
    );
    const selectionsRequireLicense =
      selectedPackage.UsesLicenses || addOnsRequiringLicense.length > 0;
    const assignableLicenses = licenses.filter(
      (x) => x.IsAutoAssignable === true,
    );
    const hasAssignableLicenses =
      assignableLicenses && assignableLicenses.length > 0;
    if (hasAssignableLicenses && selectionsRequireLicense) {
      return true;
    }
    return false;
  }
}
