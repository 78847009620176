import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, forkJoin } from "rxjs";
import { WLPService } from "../rnapi2-service/apis/wLP.service";
import { LoggedInInfoService } from "../loggedInInfo/logged-in-info.service";
import { NavigationService } from "../navigation/navigation.service";
import { HttpResponse } from "@angular/common/http";
import { LookupService, OrganizationService } from "../rnapi2-service/apis/api";
import { UserLoginState } from "../../../core/models/user.login.state";
import { startupStateTypes } from "../../../core/enums/startupStateTypes";
import {
  RnUsersProfileVMRNResponseRelay,
  RnCommonId,
  RnOrganizationConfigurationVMRNResponseRelay,
  RnOrganizationWLPContactInformationVMRNResponseRelay,
  RnWLPInformationVMListRNResponseRelay,
  RnOrganizationsProfileVMRNResponseRelay,
  RnStringStringDictionaryRNResponseRelay,
  RnStringRNResponseRelay,
} from "../rnapi2-service/models/models";
import { SignalrPollingService } from "../signalr-polling/signalr-polling.service";
import { EnvService } from "../../../core/services/env.service";
import { datadogRum } from "@datadog/browser-rum";
import { UserCacheService } from "../user-cache/user-cache.service";
import { OrganizationCacheService } from "../organization-cache/organization-cache.service";
import { AuthService } from "@rn-platform/frontend-shared-feature-identity-auth";

declare let pendo: any;

@Injectable({
  providedIn: "root",
})
export class StartupService implements OnDestroy {
  public StartupCompleted: Observable<startupStateTypes>;
  private StartupCompletedSubject = new BehaviorSubject<startupStateTypes>(
    startupStateTypes.none,
  );
  private userLoginState: UserLoginState;
  constructor(
    private loggedInInfoService: LoggedInInfoService,
    private navigationService: NavigationService,
    private orgService: OrganizationService,
    private wLPService: WLPService,
    private signalrPollingService: SignalrPollingService,
    private lookupservice: LookupService,
    private userCacheService: UserCacheService,
    private organizationCacheService: OrganizationCacheService,
    private authService: AuthService
  ) {
    this.StartupCompleted = this.StartupCompletedSubject.asObservable();
    this.subscriptions.push(
      this.StartupCompleted.subscribe(async (s) => {
        if (s === startupStateTypes.completed) {
          await this.navigationService.GoToStartPage();
        }
      }),
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  public StartUp(userLoginState: UserLoginState): void {
    // Remove a Pendo object from storage as new data is getting fetched
    localStorage.removeItem("pendoObject");
    this.userLoginState = userLoginState;
    this.StartupCompletedSubject.next(startupStateTypes.starting);
    this.subscriptions.push(
      forkJoin(
        this.LoadUserInfo(),
        this.LoadMyOrgInfo(),
        this.LoadOrgConfig(),
        this.LoadWLPContactInfo(),
        this.LoadSWSetupURL(),
      ).subscribe(
        ([
          userInfoResponse,
          myOrgInfoResponse,
          OrgConfigResponse,
          WlpContactInfoResponse,
          swSetupUrlResponse,
        ]) => {
          this.userLoginState.FirstName = userInfoResponse.body.data.FirstName;
          this.userLoginState.LastName = userInfoResponse.body.data.LastName;
          this.userLoginState.Email = userInfoResponse.body.data.EmailAddress;
          this.userLoginState.OrganizationID = myOrgInfoResponse.body.data.ID;
          this.userLoginState.OrganizationName =
            myOrgInfoResponse.body.data.Name;
          this.userLoginState.OrganizationWLPID =
            myOrgInfoResponse.body.data.WhiteLabelPartnerID;
          this.userLoginState.OrganizationWLPDescription =
            myOrgInfoResponse.body.data.PartnerName;
          this.userLoginState.UserType = userInfoResponse.body.data.UserType;
          this.userLoginState.ShowSetupWizardDownload =
            userInfoResponse.body.data.ShowSetupWizardDownload;
          this.userLoginState.AssignedPackage =
            userInfoResponse.body.data.AssignedPackage;
          this.userLoginState.AssignedAddOns =
            userInfoResponse.body.data.AssignedAddOns;
          this.userLoginState.ProfileCreated =
            userInfoResponse.body.data.ProfileCreated;
          this.userLoginState.RemoteAppEnabled =
            userInfoResponse.body.data.RemoteAppEnabled;
          this.userLoginState.OrgDataCenterTypeName =
            userInfoResponse.body.data.OrgDataCenterTypeName;
          this.userLoginState.HideSetupWizardLinks =
            OrgConfigResponse.body.data.HideSetupWizardLinks;
          this.loggedInInfoService.SetLoggedInUser(this.userLoginState);

          localStorage.setItem("alreadyLoggedIn", "true");

          this.loggedInInfoService.SetLoggedInUserOrg(
            myOrgInfoResponse.body.data,
          );
          this.loggedInInfoService.SetLoggedInUserOrgConfig(
            OrgConfigResponse.body.data,
          );

          this.loggedInInfoService.SetWLPContactInfo(
            WlpContactInfoResponse.body.data,
          );
          this.signalrPollingService.init();

          const WLPName = this.userLoginState.IsInternal()
            ? "GETALL"
            : myOrgInfoResponse.body.data.CustomerType;
          this.subscriptions.push(
            this.LoadWLPInfo(WLPName).subscribe((w) => {
              this.loggedInInfoService.SetWLPInfo(w.body.data);
              this.StartupHasCompleted();
            }),
          );

          const PWLPName = myOrgInfoResponse.body.data.CustomerType.replace(
            /\s/g,
            "",
          );
          this.subscriptions.push(
            this.LoadWLPConfig(PWLPName).subscribe((w) => {
              this.loggedInInfoService.SetWLPConfig(w.body);
              this.StartupHasCompleted();
            }),
          );

          if (pendo) {
            const orgConfig = OrgConfigResponse.body.data; // store organization config response in temp variable
            const swSetup =
              swSetupUrlResponse.body.Success && swSetupUrlResponse.body.data
                ? swSetupUrlResponse.body.data
                : "";
            const parsedJwt = this.authService.getParsedJwt();
            const pendoData = {
              visitor: {
                id:
                  (EnvService.EnvVariables().enableDebug
                    ? EnvService.EnvVariables().envPrefix.toString() + "_"
                    : "") + parsedJwt.userId,
                email: this.userLoginState.Email,
                role: this.userLoginState.UserType,
                RW_Program_Type: this.userLoginState.OrganizationWLPDescription,
                RW_User_Role: this.userLoginState.UserType,
                RW_User_Package: this.userLoginState.AssignedPackage,
                RW_Segment:
                  orgConfig.CustomerSegment === "Accounting Firm"
                    ? orgConfig.CustomerSegment
                    : orgConfig.IndustryType === "Other"
                      ? orgConfig.OtherIndustryTypeName
                      : orgConfig.IndustryType,
                RW_User_ID: this.userLoginState.UserID?.toString(),
                RW_User_Addon: this.userLoginState.AssignedAddOns,
                RW_User_Creation_Date: userInfoResponse.body.data.CreatedDate,
                ...(swSetup && { RW_SW_URL: swSetup }),
                salesforce_user_id_OS: userInfoResponse.body.data.SalesforceId,
              },
              account: {
                id:
                  (EnvService.EnvVariables().enableDebug
                    ? EnvService.EnvVariables().envPrefix.toString() + "_"
                    : "") + parsedJwt.companyId,
                RW_Org_ID: this.userLoginState.OrganizationID?.toString(),
                salesforce_org_id_OS: myOrgInfoResponse.body.data.SalesforceId,
              },
            };

            this.startPendo(pendoData);
          }

          if (EnvService.EnvVariables().enableDatadog) {
            datadogRum.init({
              applicationId: EnvService.EnvVariables().datadogApplicationId,
              clientToken: EnvService.EnvVariables().datadogClientToken,
              site: EnvService.EnvVariables().datadogSite,
              service: EnvService.EnvVariables().datadogService,
              env: EnvService.EnvVariables().datadogEnv,
              version: "1.0.0",
              sessionSampleRate: 100,
              sessionReplaySampleRate: 100,
              trackUserInteractions: true,
              defaultPrivacyLevel: "mask-user-input",
              allowedTracingUrls: [
                {
                  match: EnvService.EnvVariables().datadogTracingUrl,
                  propagatorTypes:
                    EnvService.EnvVariables().datadogPropagatorTypes,
                },
              ],
            });
            datadogRum.startSessionReplayRecording();
          }
        },
      ),
    );
  }

  public onLogout(): void {
    this.StartupCompletedSubject.next(startupStateTypes.none);
    this.signalrPollingService.ShutDown();
  }

  private StartupHasCompleted(): void {
    this.StartupCompletedSubject.next(startupStateTypes.completed);
  }

  private LoadUserInfo(): Observable<
    HttpResponse<RnUsersProfileVMRNResponseRelay>
  > {
    return this.userCacheService.getUser(this.userLoginState.userid);
  }

  private LoadMyOrgInfo(): Observable<
    HttpResponse<RnOrganizationsProfileVMRNResponseRelay>
  > {
    return this.organizationCacheService.getOrganizationProfile(
      this.userLoginState.organizationid,
    );
  }

  private LoadWLPInfo(
    wlpName,
  ): Observable<HttpResponse<RnWLPInformationVMListRNResponseRelay>> {
    return this.wLPService.apiV2WlpGetWLPInfoGet(wlpName, "response");
  }

  private LoadWLPConfig(
    wlpName,
  ): Observable<HttpResponse<RnStringStringDictionaryRNResponseRelay>> {
    return this.lookupservice.apiV2LookupGetwlpconfigGet(wlpName, "response");
  }

  private LoadOrgConfig(): Observable<
    HttpResponse<RnOrganizationConfigurationVMRNResponseRelay>
  > {
    const configPayload = new RnCommonId();
    configPayload.Id = this.userLoginState.organizationid;
    return this.orgService.apiV2OrganizationsGetorganizationconfigurationPost(
      configPayload,
      "response",
    );
  }

  private LoadWLPContactInfo(): Observable<
    HttpResponse<RnOrganizationWLPContactInformationVMRNResponseRelay>
  > {
    const configPayload = new RnCommonId();
    configPayload.Id = this.userLoginState.organizationid;
    return this.orgService.apiV2OrganizationsGetorganizationwlpcontactinfoPost(
      configPayload,
      "response",
    );
  }

  private LoadSWSetupURL(): Observable<HttpResponse<RnStringRNResponseRelay>> {
    return this.orgService.apiV2OrganizationsOrganizationIdCustomfieldCustomfieldNameGet(
      Number(this.userLoginState.organizationid),
      "SWSetupLink",
      "response",
    );
  }

  startPendo(pendoData) {
    try {
      // Store a Pendo object, to use on screen refresh
      localStorage.setItem("pendoObject", JSON.stringify(pendoData));
      if (pendo && pendo.isReady()) {
        // already initialized, so just identify the user
        pendo.identify(pendoData);
      } else {
        pendo.initialize(pendoData);
      }
    } catch (error) {
      // The function does not exist.
    }
  }

  private subscriptions = [];
}
