import { BrowserModule } from "@angular/platform-browser";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { ToastModule } from "primeng/toast";
import { AppRoutingModule, routes } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModule } from "./shared/shared.module";
import { CoreModule } from "./core/core.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgIdleModule } from "@ng-idle/core";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { AuthTokenInterceptor } from "./shared/security/auth-token-interceptor";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  LD_ENV_KEY,
  ldEnvKeyFactory,
} from "./shared/services/feature-flag-service/ld-tokens";

import { AUTH_CLIENT_CONFIG, AuthGuard, AuthService, Environment, LogLevel } from "@rn-platform/frontend-shared-feature-identity-auth";
import { EnvService } from "./core/services/env.service";
import { CookieService } from "@rn-platform/frontend-shared-data-access-cookie";
import "@angular/compiler";
import { RouterModule } from "@angular/router";

export let environmentFactory = () => {
  if (EnvService.EnvVariables().identityEnvironment == "Development") {
    return { environment: Environment.Development, logLevel: LogLevel.Debug };
  } else if (EnvService.EnvVariables().identityEnvironment == "Local") {
    return { environment: Environment.Local, logLevel: LogLevel.Debug };
  } else if (EnvService.EnvVariables().identityEnvironment == "Prod") {
    return { environment: Environment.Production, logLevel: LogLevel.Error };
  } else {
    return { environment: Environment.Staging, logLevel: LogLevel.Debug };
  }
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule.forRoot(),
    CoreModule.forRoot(),
    NgIdleModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    BrowserAnimationsModule,
    ToastModule,
    MessagesModule,
    MessageModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking', useHash: true
    })
  ],
  providers: [
    HttpClient,
    CookieService,
    AuthService,
    AuthGuard,
    {
      provide: AUTH_CLIENT_CONFIG,
      useFactory: environmentFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "fill" },
    },
    { provide: LD_ENV_KEY, useFactory: ldEnvKeyFactory },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
