export class EnvVariables {
  public baseUrl2: string;
  public version: string;
  public enableUserDemo: boolean;
  public beginYearRange: string;
  public assetUrl: string;
  public instrumentationKey: string;
  public enableDebug: boolean;
  public enableDatadog: boolean;
  public envPrefix: boolean;
  public chatType: string;
  public chatUrl: string;
  public chatEnvironment: string;
  public chatDeploymentId: string;
  public datadogApplicationId: string;
  public datadogClientToken: string;
  public datadogSite: string;
  public datadogService: string;
  public datadogEnv: string;
  public datadogTracingUrl: string;
  public datadogPropagatorTypes: any[];
}
