import { Component } from "@angular/core";
import { Keepalive } from "@ng-idle/keepalive";
import { MenuItem } from "primeng/api/menuitem";
import { delay } from "rxjs/operators";
import { startupStateTypes } from "./core/enums/startupStateTypes";
import { RnOnSidebarClose } from "./core/interfaces/RnOnSidebarClose";
import { TimeoutSettings } from "./core/models/timeoutSettings";
import { UserLoginState } from "./core/models/user.login.state";
import { EnvService } from "./core/services/env.service";
import { IdleService } from "./shared/idle/idle.service";
import { AuthenticationService } from "./shared/security/authentication.service";
import { LoggedInInfoService } from "./shared/services/loggedInInfo/logged-in-info.service";
import { ConfigurationLogicService } from "./shared/services/logic/configuration-logic/configuration-logic.service";
import { PreferencesService } from "./shared/services/preferences/preferences.service";
import {
  OrganizationService,
  RnCommonId,
} from "./shared/services/rnapi2-service";
import {
  RnsidebarService,
  RnsidebarServiceSubjectPayload,
} from "./shared/services/sidebar/rnsidebar.service";
import { StartupService } from "./shared/services/startup/startup.service";
import { WriteOffService } from "./shared/services/writeOff/write-off.service";

declare let pendo: any;

// Declare type for the Genesys function
type GenesysFunction = (
  action: string,
  event: string,
  callback: (evt: any) => void,
) => void;

declare global {
  interface Window {
    Genesys: GenesysFunction;
  }
}

const handledChatStartTimestamps = new Set<number>();

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  message: string;
  userEmail: string;
  loginResult: string;
  loggedInUser: UserLoginState;
  idleState = "NOT_STARTED";
  startupState: startupStateTypes;
  visibleSidebar = false;
  sideBarComponentName = "";
  sideBarComponentData: any;
  sideBarComponentAdditionalData: any;
  sideBarComponentStyles = "";
  pendingChanges = false;
  destorySidebar = false;
  showConfirmation = false;
  rnOnSidebarClose: RnOnSidebarClose;
  hideSalesForceChat = false;
  assetUrl: string = EnvService.EnvVariables().assetUrl;
  public menuItems: MenuItem[];
  constructor(
    private authenticationService: AuthenticationService,
    private startupService: StartupService,
    private loggedInInfoService: LoggedInInfoService,
    private idleService: IdleService,
    private preferencesService: PreferencesService,
    private keepAlive: Keepalive,
    private rnsidebarService: RnsidebarService,
    private configurationLogicService: ConfigurationLogicService,
    private writeOffService: WriteOffService,
    private organizationService: OrganizationService,
  ) {
    const configurationCallback = (timeoutSettings: TimeoutSettings) => {
      // // if user is coming back after closing the tab or browser then logout user and ask to relogin
      // // used a session storage flag ti handle it as its value is preserved till tab is open
      if (!sessionStorage.getItem("isLoggedIn")) {
        this.authenticationService.handleLogout();
      }

      this.loggedInInfoService.LoggedInUser.subscribe((u) => {
        this.loggedInUser = u;
        if (this.loggedInUser !== null) {
          if (!this.idleService.isRunning()) {
            this.idleService.setup(timeoutSettings);
            this.idleService.start();
            this.keepAlive.start();
            sessionStorage.setItem("isLoggedIn", "true");
          }

          if (!window["_genesysJS"]) {
            this.startupGenesysChat();
          }
        } else {
          this.idleService.stop();
          this.keepAlive.stop();
        }
        this.menuItems = [
          {
            label: "Log Out",
            command: async () => {
              await this.logout();
            },
          },
        ];
      });

      this.loggedInInfoService.checkForRefreshInfo();
      this.preferencesService.refreshPreferences();
      // Set KeepAlive for refreshing token
      const keepAliveInterval = timeoutSettings.keepAliveInterval;
      this.keepAlive.interval(keepAliveInterval);
      this.keepAlive.onPing.subscribe(() => {
        this.authenticationService.refreshToken();
      });

      this.startupService.StartupCompleted.pipe(delay(0)).subscribe((s) => {
        if (this.startupState != s) {
          this.startupState = s;
          if (this.startupState == startupStateTypes.completed) {
            this.writeOffService.checkHandleWLPOrgInWriteOff();
          }
        }
      });

      this.rnsidebarService
        .getObservable()
        .subscribe((payload: RnsidebarServiceSubjectPayload) => {
          this.sideBarComponentName = payload.componentName;
          this.sideBarComponentData = payload.componentData;
          this.sideBarComponentAdditionalData = payload.additionalData;
          this.sideBarComponentStyles = payload.componentStyle;
          this.pendingChanges = payload.pendingChanges;
          this.visibleSidebar = payload.visibleSidebar;
          this.destorySidebar = false;
          this.showConfirmation = payload.showConfirmation;
          this.rnOnSidebarClose = payload.rnOnSidebarClose;
        });
    };

    this.configurationLogicService.GetTimeOutSettings(configurationCallback);
  }

  startupGenesysChat(): void {
    const getOrgConfigPayload = new RnCommonId();
    getOrgConfigPayload.Id = this.loggedInInfoService
      .GetLoggedInUserOrg()
      .ID.toString();

    this.organizationService
      .apiV2OrganizationsGetorganizationconfigurationPost(getOrgConfigPayload)
      .subscribe((orgConfigResp) => {
        const enableChat = orgConfigResp.data.AllowSalesforceChat;
        if (enableChat) {
          this.hideSalesForceChat = true;
          (function (g, e, n, es, ys) {
            g["_genesysJs"] = e;
            g[e] =
              g[e] ||
              function (...args) {
                (g[e].q = g[e].q || []).push(args);
              };
            g[e].t = new Date();
            g[e].c = es;
            ys = document.createElement("script");
            ys.async = 1;
            ys.src = n;
            ys.charset = "utf-8";
            document.head.appendChild(ys);
            if (typeof g.Genesys === "function") {
              g.Genesys("subscribe", "Messenger.opened", function (evt) {
                if (!handledChatStartTimestamps.has(evt.time)) {
                  // This event fires multiple times when the button is clicked for some reason,
                  // so make sure we only handle it once
                  handledChatStartTimestamps.add(evt.time);
                  pendo.track("rw_chat_start");
                }
              });
            } else {
              console.error(
                "Genesys function is not available on the window object.",
              );
            }
          })(
            window,
            EnvService.EnvVariables().chatType,
            EnvService.EnvVariables().chatUrl,
            {
              environment: EnvService.EnvVariables().chatEnvironment,
              deploymentId: EnvService.EnvVariables().chatDeploymentId,
            },
          );
        }
      });
  }

  title = "Rightworks AppHub";

  userLoggedIn(): boolean {
    return this.loggedInUser !== null;
  }

  getLoggedInUserName(): string {
    return this.userLoggedIn()
      ? this.loggedInUser.FirstName + " " + this.loggedInUser.LastName
      : "";
  }

  showNavigation(): boolean {
    return !this.showAppLoading() && this.userLoggedIn();
  }

  showHeader(): boolean {
    return !this.showAppLoading() && this.userLoggedIn();
  }

  showFooter(): boolean {
    return !this.showAppLoading() && this.userLoggedIn();
  }

  showAppHubChat(): boolean {
    return (
      this.startupState !== startupStateTypes.starting &&
      this.userLoggedIn() &&
      !this.hideSalesForceChat
    );
  }

  showSidebar(): boolean {
    return (
      !this.showAppLoading() &&
      this.userLoggedIn() &&
      !this.sidebarExclusions.some((a) => window.location.href.indexOf(a) >= 0)
    );
  }

  showAppLoading(): boolean {
    return this.startupState === startupStateTypes.starting;
  }

  async logout(): Promise<void> {
    await this.authenticationService.logout();
  }

  onHideSideBar(NavSideBar) {
    if (this.pendingChanges) {
      this.showConfirmation = true;
    } else {
      NavSideBar.destroyModal();

      if (this.rnOnSidebarClose) {
        this.rnOnSidebarClose.onSidebarClose();
      }
      this.rnsidebarService.clear();
    }
  }
  onShowConfirmation() {
    this.visibleSidebar = true;
    this.destorySidebar = false;
  }

  receiveConfirmationResult(result) {
    if (result) {
      this.showConfirmation = false;
      this.rnsidebarService.hide();
      this.destorySidebar = true;
    } else {
      this.showConfirmation = false;
      this.visibleSidebar = true;
      this.destorySidebar = false;
    }
  }

  private sidebarExclusions: string[] = [
    "login",
    "intuitsetup",
    "newwelcomewizard",
  ];
}
