import { Component, OnDestroy, OnInit } from "@angular/core";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { Subscription } from "rxjs";
import { AppInsightsService } from "../../../appInsights/app-insights.service";
import { DialogTrackingService } from "../../../services/dialog/dialog-tracking.service";
import { LoggedInInfoService } from "../../../services/loggedInInfo/logged-in-info.service";
import {
  DuoService,
  UserService,
} from "../../../services/rnapi2-service/apis/api";
import {
  RnCommonId,
  RnUsersProfileVM,
} from "../../../services/rnapi2-service/models/models";
import { SelectionService } from "../../../services/selection/selection.service";
import { EnvService } from "../../../../core/services/env.service";

@Component({
  selector: "app-edit-mfa-dialog",
  templateUrl: "./edit-mfa-dialog.component.html",
  styleUrls: ["./edit-mfa-dialog.component.scss"],
})
export class EditMfaDialogComponent implements OnInit, OnDestroy {
  user: RnUsersProfileVM;
  isProcessing = false;
  finishedProcessing = false;
  checkedIcon = true;
  message = "";
  submessage = "";
  assetUrl: string;
  disableButtonDisableMFA = true;
  orgMFAEnabled = true;

  constructor(
    private ref: DynamicDialogRef,
    private loggedInService: LoggedInInfoService,
    private userService: UserService,
    private duoService: DuoService,
    private selectionService: SelectionService,
    private appInsightsService: AppInsightsService,
    private dialogTrackService: DialogTrackingService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.selectionService.SelectedOrgConfig.subscribe((result) => {
        this.disableButtonDisableMFA = result?.EnableMFA ? true : false;
      }),
    );

    this.assetUrl = EnvService.EnvVariables().assetUrl;
    const payload: RnCommonId = {
      Id: this.loggedInService.GetLoggedInUser().UserID.toString(),
    };
    this.subscriptions.push(
      this.userService.apiV2UsersGetuserPost(payload).subscribe((r) => {
        this.user = r.data;
      }),
    );

    this.isProcessing = true;
    this.message = "Loading...";

    this.subscriptions.push(
      this.selectionService.SelectedOrgConfig.subscribe((result) => {
        if (result) {
          this.isProcessing = false;
          this.orgMFAEnabled = result?.EnableMFA !== undefined;
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  processingState(): boolean {
    return this.finishedProcessing || this.isProcessing;
  }

  close(): void {
    this.dialogTrackService.closeDialog(this.ref);
  }

  enableMfa() {
    this.isProcessing = true;
    this.message = "Enabling MFA...";
    const payload: RnCommonId = {
      Id: this.user.UserID.toString(),
      AffectedUserId: this.user.UserID,
    };
    this.subscriptions.push(
      this.duoService.apiV2DuoEnablemfaPost(payload).subscribe((r) => {
        this.isProcessing = false;
        this.finishedProcessing = true;
        this.checkedIcon = true;
        this.message = "MFA has been enabled on your account.";
        this.submessage = "";
        this.appInsightsService.logEvent(
          "User Multi-Factor Authentication has been enabled.",
        );
      }),
    );
  }

  disableMfa() {
    this.isProcessing = true;
    this.message = "Disabling MFA...";
    const payload: RnCommonId = {
      Id: this.user.UserID.toString(),
      AffectedUserId: this.user.UserID,
    };
    this.subscriptions.push(
      this.duoService.apiV2DuoDisablemfaPost(payload).subscribe((r) => {
        this.isProcessing = false;
        this.finishedProcessing = true;
        this.checkedIcon = false;
        this.message = "MFA has been disabled on your account.";
        this.submessage = "";
        this.appInsightsService.logEvent(
          "User Multi-Factor Authentication has been disabled.",
        );
      }),
    );
  }

  resetMfa() {
    this.isProcessing = true;
    this.message = "Resetting MFA...";
    const payload: RnCommonId = {
      Id: this.user.UserID.toString(),
      AffectedUserId: this.user.UserID,
      AffectedOrganizationId: this.user.OrganizationID,
    };
    this.subscriptions.push(
      this.duoService.apiV2DuoResetuserPost(payload).subscribe((r) => {
        this.isProcessing = false;
        this.finishedProcessing = true;
        this.checkedIcon = false;
        this.message = "MFA has been reset.";
        this.submessage = "Please log out and log back in to re-enroll.";
        this.appInsightsService.logEvent(
          "User Multi-Factor Authentication has been reset.",
        );
      }),
    );
  }

  private subscriptions: Subscription[] = [];
}
