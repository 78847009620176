<ng-container *ngIf="userLoggedIn">
  <div class="row topmarg"></div>
  <div class="header" role="main" id="header">
    <div class="logo">
      <img
        alt="right-works logo"
        class="logoImage"
        src="{{ assetUrl }}/images/NewAngularRightWorksLightLogo.png"
      />
    </div>

    <div class="navbar-right" *ngIf="userLoggedIn">
      <div class="pad-well">
        <mat-menu
          #menu="matMenu"
          yPosition="below"
          xPosition="before"
          (closed)="closedMenuHandler($event)"
        >
          <div>
            <app-button
              id="btnEditProfile"
              buttonStyle="mat-menu-item"
              (clickActionEmitter)="showProfileModal()"
              label="Edit Profile Information"
              *ngIf="moveProfileFlagged"
            ></app-button>
          </div>
          <div *ngIf="showEditPackagesButton">
            <app-button
              id="btnMngPackages"
              buttonStyle="mat-menu-item"
              (clickActionEmitter)="navigateToPackageInventory()"
              label="Manage Packages and Add-ons"
              *ngIf="moveProfileFlagged"
            ></app-button>
          </div>
          <div *ngIf="showEditClientAccessButton">
            <app-button
              id="btnMngClientAccess"
              buttonStyle="mat-menu-item"
              (clickActionEmitter)="openClientAccess()"
              label="Manage Client Access"
              *ngIf="moveProfileFlagged"
            ></app-button>
          </div>
          <div>
            <app-process-button
              id="btnResetPassword"
              [Configuration]="resetUserPasswordButtonConfig"
              (ButtonClicked)="resetPassword()"
            ></app-process-button>
          </div>
          <div>
            <app-button
              id="btnSecurity"
              buttonStyle="mat-menu-item"
              imgSource="rn-shield"
              (clickActionEmitter)="launchSecurityModal()"
              *ngIf="showSecurityBtn"
              label="{{ mfaLabel }}"
              additionalContent="{{ mfaStatus }}"
              additionalContentClass="{{ mfaButtonContentStyle }}"
              additionalIconSource="{{ mfaButtonContentIcon }}"
            ></app-button>
          </div>
          <mat-divider></mat-divider>
          <div>
            <app-button
              id="btnLogout"
              buttonStyle="mat-menu-item"
              imgSource="rn-log-out"
              (clickActionEmitter)="logout()"
              label="Log out"
            ></app-button>
          </div>
        </mat-menu>
        <a
          data-testid="userName"
          class="btn btn-secondary"
          id="userName"
          [matMenuTriggerFor]="menu"
          >{{ loggedInUserName }}&nbsp;<i class="fa fa-caret-down"></i
        ></a>
      </div>
    </div>
  </div>
</ng-container>
<rw-modal
  (initModal)="modalService.add($event)"
  (destroyModal)="modalService.remove($event)"
  id="{{ UserDetailsModalInfo.id }}"
  *ngIf="moveProfileFlagged"
>
  <div *rwModalHeaderContent class="modal-header justify-content-between">
    <h3 class="modal-title">Edit Profile Information</h3>
    <rw-button
      buttonclass="btn-close"
      arialabel="close add client dialog"
      datapendoid="{{ UserDetailsModalInfo.id }}close"
      datatestid="{{ UserDetailsModalInfo.id_prefix }}close"
      (clickButton)="this.cancel()"
    >
    </rw-button>
  </div>
  <div *rwModalBodyContent>
    @if (!user) {
      <rw-spinner
        type="rw-component-spinner"
        datapendoid="add-client-spinner"
        datatestid="add-client-spinner"
      ></rw-spinner>
    } @else {
      <app-user-details-modal
        class="user-detail-info-panel"
        [user]="user"
        [parentForm]="userDetailsForm"
        [events]="eventsSubject.asObservable()"
      ></app-user-details-modal>
    }
  </div>
</rw-modal>
