<div class="row">
  <div class="col-12">
    <div class="step-desc">
      If you need to make any changes, click the Back button to navigate to the
      screen that needs to be changed. If everything looks good, click Add User.
    </div>
    <div class="col-lg-12 no-padding">
      <div class="well">
        <div class="row user-summary userDetailHeading">
          <h4>User Details</h4>
        </div>
        <div class="row user-summary">
          <div class="col-12">
            <span class="title">User Name:</span>
          </div>
          <div class="col-12">
            <span class="user-details"
              >{{ firstName.value }} {{ " " }} {{ lastName.value }}</span
            >
          </div>
        </div>

        <div class="row user-summary">
          <div class="col-12">
            <span class="title">Email Address:</span>
          </div>
          <div class="col-12">
            <app-label
              cssClass="user-details"
              [value]="email.value"
              [truncateSize]="55"
            >
            </app-label>
          </div>
        </div>

        <div class="row user-summary">
          <div class="col-12">
            <span class="title">Role:</span>
          </div>
          <div class="col-12">
            <span class="user-details">{{ role.value.Name }}</span>
          </div>
        </div>

        <div class="row user-summary">
          <div class="col-12">
            <span class="title">Package:</span>
          </div>
          <div class="col-12">
            <span class="user-details">{{
              selectedPackage.value.PackageName
                ? selectedPackage.value.PackageName
                : "No package selected"
            }}</span>
          </div>
        </div>

        <div class="row user-summary">
          <div class="col-12">
            <span class="title">Add-ons:</span>
          </div>
          <div class="col-12">
            <span *ngFor="let addon of selectedAddons">
              <span class="user-details addons">{{ addon.PackageName }}</span>
              <br />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="row"
  *ngIf="shoppingCart.items.length > 0 || shoppingCart.CartTotal > 0"
>
  <div class="col-12">
    <br />
    <div class="col-lg-12 cart-review">
      <div class="userDetailHeading">
        <h4>Order Details</h4>
      </div>
      <table
        class="rn-table rn-table-withPayment table-total-width rn-table-striped"
      >
        <thead>
          <tr>
            <th
              [ngClass]="{
                shoppingCartTableProductWithDiscountCol: hasDiscounts,
                shoppingCartTableProductCol: !hasDiscounts
              }"
            >
              Product
            </th>
            <th class="c-align shoppingCartTableQtyCol">Quantity</th>
            <th
              class="r-align"
              [ngClass]="{
                'p-r-8 shoppingCartTablePriceColWithDiscount': hasDiscounts,
                shoppingCartTablePriceNTotalCol: !hasDiscounts
              }"
            >
              Item Price
            </th>
            <th
              *ngIf="hasDiscounts"
              class="shoppingCartTableDiscountCol no-padding-left"
            ></th>
            <th class="r-align shoppingCartTablePriceNTotalCol">Total Price</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of shoppingCart.items">
            <td>{{ item.Name }}</td>
            <td class="c-align" [ngClass]="{ 'p-r-8': hasDiscounts }">
              {{ item.Quantity }}
            </td>
            <td class="r-align">
              <div
                class="ws-nowrap"
                [ngClass]="{ preDiscountedPrice: item.DiscountPercent > 0 }"
              >
                {{ item.Price | currency }} /month
              </div>
              <div class="ws-nowrap" *ngIf="item.DiscountPercent > 0">
                {{
                  item.Price * ((100 - item.DiscountPercent) / 100) | currency
                }}
                /month
              </div>
            </td>
            <td *ngIf="hasDiscounts" class="l-align no-padding-left">
              <br />
              <div class="ws-nowrap" *ngIf="item.DiscountPercent > 0">
                ({{ item.DiscountPercent }}% off)
              </div>
            </td>
            <td class="r-align">
              <div
                class="ws-nowrap"
                [ngClass]="{ preDiscountedPrice: item.DiscountPercent > 0 }"
              >
                {{ item.Price * item.Quantity | currency }} /month
              </div>
              <div class="ws-nowrap" *ngIf="item.DiscountPercent > 0">
                {{
                  item.Price * ((100 - item.DiscountPercent) / 100) | currency
                }}
                /month
              </div>
            </td>
          </tr>

          <tr *ngIf="shoppingCart.items.length > 0" class="totalDueRow">
            <td class="v-align-top">
              <span><b>Total Due:</b></span>
            </td>
            <td [attr.colspan]="hasDiscounts ? 4 : 3" class="r-align">
              <div>
                <span
                  ><b>{{ shoppingCart.CartTotal | currency }}</b></span
                >
                <br />
                <span class="taxApplicableText">Plus tax where applicable</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div *ngIf="showFutureMonthlyBill" class="futureBillDivision dspy-flex">
        <span class="futureBill pull-left"
          >Future Monthly Bill (starts
          {{ paymentInfo?.NextBillDate | date: "MM/dd/yyyy" }})</span
        >
        <span class="futureBill lmargin-auto" *ngIf="recalculatingTotal"
          >Calculating&nbsp;<app-icon
            source="rn-circle-notch"
            imgClass="loading-spinner gray-icon "
          ></app-icon
        ></span>
        <span class="futureBill lmargin-auto" *ngIf="!recalculatingTotal"
          >{{ futureMonthlyBill | currency }}/month</span
        >
      </div>
      <div
        class="shoppingCartPaymentInfo futureBill promoCode"
        *ngIf="canShowPromoCodeEntry"
      >
        <span class="promo-label">Promo Code</span>
        <br />
        <div class="form-group disp-inl-flex">
          <input
            id="promoTextbox"
            maxlength="25"
            autocomplete="off"
            (focus)="error = false"
            [ngClass]="{ 'sharp-angles-error': error }"
            type="text"
            [(ngModel)]="inputPromoCode"
            placeholder="Promo Code"
            class="textbox-sharp-angles"
            (keyup)="onPromoChange()"
          />
          <button
            [disabled]="!inputPromoCode"
            type="button"
            (click)="validatePromoCode(inputPromoCode)"
            [ngClass]="
              promoCodeIsValid === false && wait === false
                ? 'blue-promo-apply-btn'
                : 'blue-promo-apply-btn blue-promo-apply-btn-default-pointer'
            "
            data-testid="btnReviewUserApplyPromo"
          >
            <span *ngIf="!promoCodeIsValid && !wait">APPLY</span>
            <span *ngIf="wait === true"
              ><app-icon
                source="rn-circle-notch"
                imgClass="loading-spinner gray-icon "
              ></app-icon
            ></span>
            <span *ngIf="promoCodeIsValid === true"
              ><i class="fa fa-check promo-apply-check" aria-hidden="true"></i
            ></span>
          </button>
        </div>
        <div class="red-help-block" *ngIf="error">
          Promo Code {{ inputPromoCode }} is not valid or has expired.
        </div>
      </div>
      <div class="shoppingCartPaymentInfo futureBill">
        <div class="subTitleText m-b-11 black">Current Payment Method</div>
        <div
          class="paymentDetails"
          *ngIf="paymentInfo?.PaymentMethods[0]?.PaymentMethodType === 'ACH'"
        >
          <div>ACH</div>
          <div>
            Account Type: {{ paymentInfo?.PaymentMethods[0]?.ACHTypeName }}
          </div>
          <div>{{ paymentInfo?.PaymentMethods[0]?.AccountLast4 }}</div>
        </div>
        <div
          class="paymentDetails"
          *ngIf="paymentInfo?.PaymentMethods[0]?.PaymentMethodType !== 'ACH'"
        >
          <div data-testid="PaymentInfo">
            {{ paymentInfo?.PaymentMethods[0]?.PaymentMethodType }}
            {{ paymentInfo?.PaymentMethods[0]?.AccountLast4 }}
          </div>
          <div data-testid="expDate">
            Expires
            {{ paymentInfo?.PaymentMethods[0]?.CCExpireOn | date: "MM/yyyy" }}
          </div>
        </div>

        <div class="m-t-14 paymentDetails">
          <span data-testid="changePaymentTxt"
            >You can change your payment and billing information on the Billing
            tab.</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
