<div class="card app-tile" [ngClass]="{ 'new-card': canUseNewTiles }">
  <div class="card-body app-tile-body" [ngClass]="{ 'p-0': canUseNewTiles }">
    <mat-card-title>
      <!--icon-->
      <div
        class="icon-frame"
        [ngClass]="{ 'new-icon-frame-size': canUseNewTiles }"
      >
        <img
          src="{{ card.thumbnailUrl }}"
          class="app-icon"
          [ngClass]="{ 'card-img': canUseNewTiles }"
          alt="app-icon"
        />
      </div>
      <!--app name-->
      <ng-container *ngIf="!canUseNewTiles">
        <div
          *ngIf="
            card | rnApplicationCard: 'iserrordisplaytype';
            else noErrorDiv
          "
        >
          <mat-card-title-text>
            <div class="row">
              <div class="col-10">
                <p class="card-text app-name">{{ card?.Name }}</p>
              </div>
              <div *ngIf="card.canInstall" class="col-2">
                <app-rnpopover
                  [popoverText]="card.popoverText"
                  class="rnpopover"
                ></app-rnpopover>
              </div>
            </div>
          </mat-card-title-text>
        </div>
        <ng-template #noErrorDiv>
          <mat-card-title-text>
            <div class="row">
              <div class="col-10">
                <p class="card-text app-name-no-hover">{{ card?.Name }}</p>
              </div>
              <div
                *ngIf="card.canInstall"
                class="col-2"
                style="margin-top: 15px"
              >
                <app-rnpopover
                  [popoverText]="card.popoverText"
                  class="rnpopover"
                ></app-rnpopover>
              </div>
            </div>
          </mat-card-title-text>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="canUseNewTiles">
        <mat-card-title-text>
          <div class="row">
            <div class="col-12 p-0">
              <p
                class="card-text app-name-no-hover card-title"
                style="width: 100%"
                [pTooltip]="card?.Name"
                [tooltipPosition]="'top'"
              >
                {{ card?.Name }}
              </p>
            </div>
          </div>
        </mat-card-title-text>
      </ng-container>
    </mat-card-title>
    <!--text flows-->
    <!--text flows-->
    <ng-container *ngIf="!canUseNewTiles">
      <mat-card-content>
        <div [ngSwitch]="card | rnApplicationCard: 'displaytype'">
          <div *ngSwitchCase="'Installing'" class="install-remove pull-left">
            <div class="width-15px">
              <app-icon
                source="rn-circle-notch"
                imgClass="loading-spinner gray-icon "
              ></app-icon>
            </div>
            &nbsp;&nbsp;
            <p id="processing">Installing...({{ card.percentComplete }}%)</p>
          </div>
          <div *ngSwitchCase="'Removing'" class="install-remove pull-left">
            <div class="width-15px">
              <app-icon
                source="rn-circle-notch"
                imgClass="loading-spinner gray-icon "
              ></app-icon>
            </div>
            &nbsp;&nbsp;
            <p id="processing">Removing...({{ card.percentComplete }}%)</p>
          </div>
          <div *ngSwitchCase="'InstallError'" class="error">
            <i class="fa fa-exclamation-triangle fa-fw" aria-hidden="true"></i>
            <p id="error-text">Install failed. Please try again.</p>
          </div>
          <div *ngSwitchCase="'RemoveError'" class="error">
            <i class="fa fa-exclamation-triangle fa-fw" aria-hidden="true"></i>
            <p id="error-text">Remove failed. Please try again.</p>
          </div>
          <div *ngSwitchCase="'InstallFailure'" class="failure">
            <p id="error-text">
              Sorry, we are not able to add the application at this time. Please
              contact Support at 1-888-417-4448 Ext. 2 for help.
            </p>
          </div>
          <div *ngSwitchCase="'RemoveFailure'" class="failure">
            <p id="error-text">
              Sorry, we are not able to remove the application at this time.
              Please contact Support at 1-888-417-4448 Ext. 2 for help.
            </p>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <!--buttons locked position-->
        <div [ngSwitch]="true" class="button-container">
          <div class="uninstall-open-frame">
            <div
              *ngSwitchCase="
                (card | rnApplicationCard: 'displaytype') === 'Installed' ||
                (card | rnApplicationCard: 'displaytype') === 'RemoveError'
              "
              class="uninstall-open-frame"
            >
              <app-button
                id="btnOpen_{{ card.Name | rnText: 'formatlowercasenospace' }}"
                [label]="card.isOpening ? 'OPENING...' : 'OPEN'"
                *ngIf="this.card.RemoteAppEnabled"
                [disabled]="card.isOpening"
                buttonStyle="primary"
                noContentPadding="true"
                (clickActionEmitter)="open()"
                width="200px"
              >
              </app-button>
              <div class="uninstall-link-frame" [ngSwitch]="linkButtonDisabled">
                <div *ngSwitchCase="true" class="rn-text-link:disabled">
                  Uninstall
                </div>
                <div
                  tabindex="0"
                  (keyup.enter)="uninstall()"
                  *ngSwitchCase="false"
                  class="rn-text-link"
                  (click)="uninstall()"
                >
                  Uninstall
                </div>
                <div
                  tabindex="0"
                  (keyup.enter)="uninstall()"
                  *ngSwitchDefault
                  class="rn-text-link"
                  (click)="uninstall()"
                >
                  Uninstall
                </div>
              </div>
            </div>
            <div
              *ngSwitchCase="
                (card | rnApplicationCard: 'displaytype') === 'Removed' ||
                (card | rnApplicationCard: 'displaytype') === 'InstallError'
              "
            >
              <div
                [tooltipDisabled]="!card.BlockingFlavorId"
                pTooltip="{{
                  card | rnApplicationCard: 'formatgroupname'
                }} and {{
                  card | rnApplicationCard: 'formatblockingflavorname'
                }} cannot be installed at the same time. To use this edition, please uninstall the other edition first by clicking on the Remove button. Your data will be preserved."
              >
                <app-button
                  id="btnInstall_{{
                    card.Name | rnText: 'formatlowercasenospace'
                  }}"
                  label="INSTALL"
                  imgSource="rn-plus-square"
                  [disabled]="
                    card.ProvisioningState === 'Installing' ||
                    !(card | rnApplicationCard: 'caninstall')
                  "
                  buttonStyle="secondary"
                  (clickActionEmitter)="install()"
                  width="200px"
                >
                </app-button>
              </div>
            </div>
          </div>
        </div>
      </mat-card-actions>
    </ng-container>
    <ng-container *ngIf="canUseNewTiles">
      <div class="p-flex" style="bottom: 10px; position: absolute">
        <div>
          <mat-card-actions>
            <!--buttons locked position-->
            <div [ngSwitch]="true" class="button-container mt-0">
              <div class="uninstall-open-frame pt-0">
                <div
                  *ngSwitchCase="
                    (card | rnApplicationCard: 'displaytype') === 'Installed' ||
                    (card | rnApplicationCard: 'displaytype') ===
                      'RemoveError' ||
                    (card | rnApplicationCard: 'displaytype') ===
                      'RemoveFailure'
                  "
                  class="uninstall-open-frame pt-0"
                >
                  <div class="uninstall-link-frame">
                    <app-button
                      id="btnUninstall_{{
                        card.Name | rnText: 'formatlowercasenospace'
                      }}"
                      [disabled]="linkButtonDisabled"
                      buttonStyle="primary"
                      noContentPadding="true"
                      (clickActionEmitter)="confirmUninstall()"
                      [imgSource]="'rn-trash-2'"
                      [buttonStyle]="'icon'"
                      [tooltip]="getTooltip(card)"
                      [tooltipDisabled]="!getTooltip(card)"
                      [tooltipsPosition]="'top'"
                    >
                    </app-button>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-actions>
        </div>
        <div>
          <mat-card-actions>
            <!--buttons locked position-->
            <div [ngSwitch]="true" class="button-container mt-0">
              <div class="uninstall-open-frame pt-0">
                <div
                  *ngSwitchCase="
                    (card | rnApplicationCard: 'displaytype') === 'Installed' ||
                    (card | rnApplicationCard: 'displaytype') === 'RemoveError'
                  "
                  class="uninstall-open-frame pt-0"
                >
                  <app-button
                    id="btnOpen_{{
                      card.Name | rnText: 'formatlowercasenospace'
                    }}"
                    [label]="card.isOpening ? 'OPENING...' : 'OPEN'"
                    *ngIf="this.card.RemoteAppEnabled"
                    [disabled]="card.isOpening"
                    buttonStyle="changeSeatCount"
                    noContentPadding="true"
                    (clickActionEmitter)="open()"
                    [tooltipsPosition]="'top'"
                  >
                  </app-button>
                </div>
                <div
                  *ngSwitchCase="
                    (card | rnApplicationCard: 'displaytype') === 'Removed' ||
                    (card | rnApplicationCard: 'displaytype') ===
                      'InstallError' ||
                    (card | rnApplicationCard: 'displaytype') ===
                      'Installing' ||
                    (card | rnApplicationCard: 'displaytype') ===
                      'InstallFailure'
                  "
                >
                  <div
                    [tooltipDisabled]="!card.BlockingFlavorId"
                    pTooltip="{{
                      card | rnApplicationCard: 'formatgroupname'
                    }} and {{
                      card | rnApplicationCard: 'formatblockingflavorname'
                    }} cannot be installed at the same time. To use this edition, please uninstall the other edition first by clicking on the Remove button. Your data will be preserved."
                  >
                    <app-button
                      id="btnInstall_{{
                        card.Name | rnText: 'formatlowercasenospace'
                      }}"
                      label="INSTALL"
                      [disabled]="
                        card.ProvisioningState === 'Installing' ||
                        card.ProvisioningState === 'InstallFailure' ||
                        !(card | rnApplicationCard: 'caninstall')
                      "
                      buttonStyle="changeSeatCount"
                      (clickActionEmitter)="install()"
                      [tooltip]="getTooltip(card)"
                      noContentPadding="true"
                      [tooltipDisabled]="!getTooltip(card)"
                      [tooltipsPosition]="'top'"
                    >
                    </app-button>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-actions>
        </div>
      </div>
    </ng-container>
  </div>
</div>
