import { HttpResponse } from "@angular/common/http";
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { forkJoin, Observable, Subscription } from "rxjs";
import { OrganizationCatalogInfoVM } from "src/app/shared/services/rnapi2-service/models/OrganizationCatalogInfoVM";
import { CartService } from "../../../services/cart/cart.service";
import { RnDialogService } from "../../../services/dialog/rndialog.service";
import { LoggedInInfoService } from "../../../services/loggedInInfo/logged-in-info.service";
import {
  OrganizationService,
  UserService,
} from "../../../services/rnapi2-service/apis/api";
import {
  RnCommonId,
  RnCommonSearch,
  RnOrganizationConfigurationVM,
  RnOrganizationConfigurationVMRNResponseRelay,
  RnOrganizationPaymentOnFileVM,
  RnOrganizationsProfileVM,
  RnOrganizationsProfileVMRNResponseRelay,
  RnOrganizationWLPContactInformationVM,
  RnOrganizationWLPContactInformationVMRNResponseRelay,
  RnUserPackagePoolAction,
  RnUsersProfileVM,
} from "../../../services/rnapi2-service/models/models";
import { RnsidebarService } from "../../../services/sidebar/rnsidebar.service";
import { RnToastService } from "../../../services/toast/rntoast.service";
import { PackageCardData } from "../package-card/package-card-data";
import { Guid } from "src/app/shared/utilities/guid/guid.utilities";
import { SignalrPollingService } from "src/app/shared/services/signalr-polling/signalr-polling.service";
import { SignalrPollingBaseMessage } from "src/app/core/models/signalr/signalr-polling-base-message";

@Component({
  selector: "app-manage-packages-sidebar",
  templateUrl: "./manage-packages-sidebar.component.html",
  styleUrls: ["./manage-packages-sidebar.component.scss"],
})
export class ManagePackagesSidebarComponent implements OnInit, OnDestroy {
  @ViewChild("cartIcon") cartIcon: ElementRef;
  @ViewChild("cart") cart: ElementRef;

  @Input() data: any;
  @Output() componentLoaded = new EventEmitter<boolean>();

  public packageData: PackageCardData[];
  public paymentInfo: RnOrganizationPaymentOnFileVM;
  public packagesForm: FormGroup;
  public orgProfile: RnOrganizationsProfileVM;
  public orgConfig: RnOrganizationConfigurationVM;
  public orgWlpContactInfo: RnOrganizationWLPContactInformationVM;
  public subscriptions: Subscription[] = [];
  public addOns: PackageCardData[];
  public assignedPackage: string;
  public organizationId: number;
  public isShoppingCartVisible = false;
  public fullName: string;
  public userType: string;
  public loading = false;
  public checkForInitialActiveMessage = false;
  public selectedPackage: PackageCardData;
  public availableAddOns: PackageCardData[];
  private totalPkgCount = 0;
  private packageswithPackagePools: PackageCardData[];
  private keepOldPackage: boolean;
  private hasRightToPurchase: boolean;
  private canAddDrop: boolean;
  private initialPackage: PackageCardData;
  private selectedUser?: any;
  public userData: RnUsersProfileVM;
  getWlpPackageText = "";
  partnerName: string;
  public showPkgSubtextSection = false;
  public cancellations_phone: string;
  public technical_support_phone: string;

  private signalRHandlerID: Guid;

  constructor(
    private formBuilder: FormBuilder,
    private orgService: OrganizationService,
    private cartService: CartService,
    private renderer: Renderer2,
    private userService: UserService,
    private rnsidebarService: RnsidebarService,
    private rnToastService: RnToastService,
    private loggedInInfoService: LoggedInInfoService,
    private organizationService: OrganizationService,
    private rnDialogService: RnDialogService,
    private signalrPollingService: SignalrPollingService,
  ) {}

  @HostListener("document:click", ["$event"])
  onClick(event: MouseEvent) {
    const clickedOverlay = event.target as HTMLElement;
    if (clickedOverlay?.classList.contains("p-component-overlay-leave")) {
      this.dismiss();
    }
  }

  ngOnInit(): void {
    this.componentLoaded.emit(false);
    this.renderer.listen("window", "click", (e: Event) => {
      if (
        !this.cartIcon.nativeElement.contains(e.target) &&
        !this.cart.nativeElement.contains(e.target)
      ) {
        this.forceCloseShoppingCart();
      }
    });

    this.cartService.clearCart();
    this.packagesForm = this.formBuilder.group({
      selectedPackage: ["", Validators.required.bind(Validators)],
    });

    const loggedInUser = this.loggedInInfoService.GetLoggedInUser();
    if (this.loggedInInfoService.GetWLPConfig) {
      const wlpConfigData = this.loggedInInfoService?.GetWLPConfig()?.data;
      if (wlpConfigData) {
        this.cancellations_phone =
          Object.values(wlpConfigData)[
            Object.keys(wlpConfigData).indexOf("cancellations_phone_number")
          ];
        this.technical_support_phone =
          Object.values(wlpConfigData)[
            Object.keys(wlpConfigData).indexOf("technical_support_phone_number")
          ];
      }
    }
    const payloadCommonId = new RnCommonId();
    payloadCommonId.Id = this.data.userId;

    this.subscriptions.push(
      this.userService
        .apiV2UsersGetuserPost(payloadCommonId)
        .subscribe((response) => {
          this.userData = this.selectedUser = response.data;
          this.assignedPackage = response.data.AssignedPackage;
          this.organizationId = response.data.OrganizationID;
          this.fullName = `${response.data.FirstName} ${response.data.LastName}`;
          this.userType = response.data.UserType;

          this.subscriptions.push(
            forkJoin(
              this.loadOrganizationData(this.organizationId.toString()),
              this.loadOrganizationConfig(this.organizationId.toString()),
              this.loadOrganizationWLPConfig(this.organizationId.toString()),
            ).subscribe(([orgProfile, orgConfig, orgWlpContactInfo]) => {
              this.orgProfile = orgProfile.body.data;
              this.orgConfig = orgConfig.body.data;
              this.orgWlpContactInfo = orgWlpContactInfo.body.data;

              const orgIsInWriteOff =
                this.orgProfile.Status === "WriteOff" ||
                this.orgProfile.StatusDesc === "Write-Off";
              const orgIsSuspended =
                this.orgProfile.Status === "Suspended" ||
                this.orgProfile.StatusDesc === "Suspended";
              const orgIsInAddOnSuspended =
                this.orgProfile.Status === "AddOnSuspended" ||
                this.orgProfile.StatusDesc === "Add-ons Suspended";

              this.hasRightToPurchase =
                this.loggedInInfoService.loggedInUserHasRights([
                  "PCHDRPKUSR",
                ]) &&
                (loggedInUser.IsInternal() ||
                  this.organizationId ===
                    this.orgProfile.IdThatPaysForThisOrg) &&
                !orgIsInWriteOff &&
                !orgIsSuspended &&
                !orgIsInAddOnSuspended;

              this.keepOldPackage = false;
              if (!this.hasRightToPurchase) {
                this.keepOldPackage = true;
              }

              this.loggedInInfoService.LoggedInUserOrgPaymentOnFile.subscribe(
                (p) => {
                  this.paymentInfo = p;
                },
              );

              const payload = new RnCommonId();
              payload.Id = this.organizationId.toString();
              payload.AffectedOrganizationId = this.organizationId;

              this.subscriptions.push(
                this.loadOrganizationPackageData().subscribe((r) => {
                  if (r.body.Success) {
                    this.packageData = [];
                    const pac = r.body.data.CatalogItems.filter(
                      (p) => "package" == p.PackageUIType,
                    );
                    pac.forEach((p) => {
                      const pkg = new PackageCardData();
                      pkg.AssignFromRnCatalogItemVM(p);
                      this.packageData.push(pkg);
                    });
                    const addOnsList = [];
                    this.packageData.forEach((p) => {
                      if (
                        p.PackageUIType === "addon" &&
                        p.PackageTypeID !== 6
                      ) {
                        addOnsList.push(p);
                      }
                    });

                    this.addOns = addOnsList;
                    if (this.assignedPackage) {
                      const selectedpackageList = this.packageData.filter(
                        (x) => x.PackageName === this.assignedPackage,
                      );
                      if (selectedpackageList.length > 0) {
                        const pkg = selectedpackageList[0];
                        pkg.Selected = true;

                        this.initialPackage = pkg;

                        this.addOns.forEach((a) => {
                          if (a.AddOnAdded) {
                            a.AddOnAdded = false;
                            if (a.BuyNew) {
                              this.cartService.removeFromCart(a);
                              a.BuyNew = false;
                              a.CurrentQuantity--;
                            }
                            a.CurrentUsed--;
                          }
                        });
                      }
                    } else {
                      this.initialPackage = new PackageCardData();
                      this.initialPackage.PackagePoolID = -2;
                    }
                    this.packageswithPackagePools = this.packageData
                      .filter(
                        (pkg) =>
                          !(
                            (
                              pkg.CatalogVisibility == "Unpurchasable" &&
                              pkg.CurrentQuantity == 0
                            )
                            // filter packages that dont have a category name OR are part of the OneSpaceDesktop category
                          ) &&
                          (!pkg.CatalogCategoryName ||
                            pkg.CatalogCategoryName === "OneSpaceDesktop"),
                      )
                      .sort((x) => x.SortOrder);
                    this.packageswithPackagePools.forEach((pkg) => {
                      this.totalPkgCount += pkg.CurrentQuantity;
                    });

                    const canPurchaseApps =
                      this.packageswithPackagePools.filter(
                        (p) => p.CatalogVisibility != "Unpurchasable",
                      ).length > 0;
                    this.canAddDrop = canPurchaseApps;
                    this.componentLoaded.emit(true);
                  }
                }),
              );
            }),
          );
        }),
    );

    this.signalRHandlerID = this.signalrPollingService.AddMessageHandler(
      (message) => {
        const notification = JSON.parse(message) as SignalrPollingBaseMessage;
        if (
          notification &&
          notification.Payload &&
          notification.Payload.AreaDataType.toUpperCase() ===
            "APPLICATION_INSTALL_PAYLOAD" &&
          notification.Payload.Area.toUpperCase() === "USER" &&
          notification.Payload.AreaID ===
            this.loggedInInfoService.GetLoggedInUser().UserID
        ) {
          const data = JSON.parse(notification.Payload.AreaData);
          if (data.Status === "Removing" || data.Status === "Installing") {
            setTimeout(() => {
              this.rnsidebarService.startLoading();
              this.rnsidebarService.refreshUser();
            }, 2000);
          }
        }
      },
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });

    this.cartService.clearCart();
  }

  public GetWlpPackageText(): void {
    const payload = new RnCommonSearch();
    this.showPkgSubtextSection = false;
    if (
      this.orgProfile?.CustomerType != "Rightworks" &&
      this.orgProfile?.ParentID < 0
    ) {
      this.partnerName = this.orgProfile.PartnerName;
      this.showPkgSubtextSection = true;
      if (this.loggedInInfoService.GetWLPConfig) {
        const wlpConfigData = this.loggedInInfoService?.GetWLPConfig()?.data;
        if (wlpConfigData) {
          this.getWlpPackageText =
            Object.values(wlpConfigData)[
              Object.keys(wlpConfigData).indexOf("change_pkg_message")
            ];
        }
      }
    }
  }

  private loadOrganizationData(
    orgId: string,
  ): Observable<HttpResponse<RnOrganizationsProfileVMRNResponseRelay>> {
    const payload = new RnCommonId();
    payload.Id = orgId;
    return this.orgService.apiV2OrganizationsProfilePost(payload, "response");
  }

  private loadOrganizationConfig(
    orgId: string,
  ): Observable<HttpResponse<RnOrganizationConfigurationVMRNResponseRelay>> {
    const payload = new RnCommonId();
    payload.Id = orgId;
    return this.orgService.apiV2OrganizationsGetorganizationconfigurationPost(
      payload,
      "response",
    );
  }

  private loadOrganizationWLPConfig(
    orgId: string,
  ): Observable<
    HttpResponse<RnOrganizationWLPContactInformationVMRNResponseRelay>
  > {
    const payload = new RnCommonId();
    payload.Id = orgId;
    return this.orgService.apiV2OrganizationsGetorganizationwlpcontactinfoPost(
      payload,
      "response",
    );
  }

  private loadOrganizationPackageData(): Observable<
    HttpResponse<OrganizationCatalogInfoVM>
  > {
    const payload = new RnCommonId();
    payload.Id = this.organizationId.toString();
    payload.AffectedOrganizationId = this.organizationId;
    return this.orgService.apiV2OrganizationsGetcatalogitemsfororgPost(
      payload,
      "response",
    );
  }

  forceCloseShoppingCart(): void {
    this.isShoppingCartVisible = false;
  }

  toggleShoppingCart(): void {
    this.isShoppingCartVisible = !this.isShoppingCartVisible;
  }

  dismiss() {
    if (this.canSubmit()) {
      this.rnsidebarService.showConfirmation();
    } else {
      this.rnsidebarService.hide();
    }
  }

  public saveChanges() {
    const catVis = this.packageswithPackagePools.filter(
      (p) => p.PackageName === this.selectedUser.AssignedPackage,
    );
    if (
      this.initialPackage.PackageID > 0 &&
      catVis.length > 0 &&
      catVis[0].CatalogVisibility !== "Unpurchasable"
    ) {
      this.selectedUser.DirectBilledPackages = [
        this.initialPackage.PackageName,
      ];

      const firstName: string = this.selectedUser.FirstName;
      const lastName: string = this.selectedUser.LastName;
      this.selectedUser.FullName = `${firstName} ${lastName}`;
      const dialogRef = this.rnDialogService.PreviousPackageActionDialog(
        [this.selectedUser],
        "changePackage",
        catVis[0].CatalogVisibility,
        false,
      );

      let choseOption = false;

      const drCloseSub = dialogRef.onClose.subscribe((s) => {
        if (s) {
          choseOption = true;

          // wait to open a new dialog since this one hasn't completed closing yet!
          if (s == "true") this.keepOldPackage = true;
          else this.keepOldPackage = false;
        } else {
          choseOption = false;
        }
      });
      this.subscriptions.push(drCloseSub);

      const drDestroySub = dialogRef.onDestroy.subscribe((s) => {
        if (choseOption) {
          if (this.lastPackage()) {
            this.rnToastService.showError(
              `Need to drop your final package and cancel your Rightworks account? Please contact us for ${this.cancellations_phone}.`,
            );
          } else {
            this.save();
          }
        }
      });
      this.subscriptions.push(drDestroySub);
    } else {
      // call delete function
      this.save();
    }
  }

  private save() {
    this.loading = true;

    const promoID =
      this.cartService.promo && this.cartService.promo.promoCode
        ? this.cartService.promo.promoCode.ID
        : -1;

    const packageActionPayload: RnUserPackagePoolAction = {
      AffectedOrganizationId: this.orgProfile.IdThatPaysForThisOrg,
      Users_ID: this.data.userId,
      ToPackage_ID: this.selectedPackage.PackageID,
      AtomicAction: this.getAtomicAction(
        this.selectedPackage,
        this.initialPackage,
        this.initialPackage?.CatalogVisibility !== "Unpurchasable" &&
          this.canAddDrop
          ? this.keepOldPackage
          : true,
      ),
      KeepOldPackage:
        this.initialPackage?.CatalogVisibility !== "Unpurchasable" &&
        this.canAddDrop
          ? this.keepOldPackage
          : true,
      PromotionCodeID: promoID,
    };

    this.subscriptions.push(
      this.userService
        .apiV2UsersPackagepoolactionPost(packageActionPayload)
        .subscribe(
          (response) => {
            if (response) {
              setTimeout(() => {
                this.loading = false;
                this.rnToastService.showSuccess("Package changed successfully");
                this.rnsidebarService.refreshUser();
              }, 3000);
              const currentUser = this.loggedInInfoService.GetLoggedInUser();
              if (currentUser.UserID == this.data.userId) {
                currentUser.AssignedPackage = this.selectedPackage.PackageName;
                this.loggedInInfoService.SetLoggedInUser(currentUser);
              }
            }
          },
          (error) => {
            this.loading = false;
            this.rnToastService.showError(
              `An error occured when changing packages. Please contact ${this.technical_support_phone}.`,
            );
            console.log(error);
          },
        ),
    );
  }

  private getAtomicAction(
    toPackage: PackageCardData,
    fromPackage: PackageCardData,
    keepOld: boolean,
  ) {
    const ownsToPackage = !toPackage.BuyNew;
    if (toPackage.PackagePoolID == -2) {
      if (keepOld) {
        return "reassign";
      } else {
        return "drop";
      }
    } else if (fromPackage.PackagePoolID == -2) {
      if (ownsToPackage) {
        return "reassign";
      } else {
        return "add";
      }
    }
    const toHigherPrice = toPackage.ListPrice > fromPackage.ListPrice;
    if (toHigherPrice) {
      if (ownsToPackage) {
        if (keepOld) {
          return "reassign";
        } else {
          return "drop";
        }
      } else {
        if (keepOld) {
          return "add";
        } else {
          return "upgrade";
        }
      }
    } else {
      if (ownsToPackage) {
        if (keepOld) {
          return "reassign";
        } else {
          return "drop";
        }
      } else {
        if (keepOld) {
          return "add";
        } else {
          return "downgrade";
        }
      }
    }
  }

  private lastPackage(): boolean {
    return (
      this.totalPkgCount < 2 &&
      this.selectedPackage.PackagePoolID == -2 &&
      (this.initialPackage?.CatalogVisibility !== "Unpurchasable" &&
      this.canAddDrop
        ? this.keepOldPackage
        : true) == false
    );
  }

  packageSelected(pkg: PackageCardData) {
    this.selectedPackage = pkg;
  }

  packagesLoaded(packages: PackageCardData[]) {
    this.GetWlpPackageText();
    //this.packages = packages.filter(p => 'package' == p.PackageUIType);
  }

  canSubmit() {
    return (
      !this.loading &&
      this.selectedPackage?.PackagePoolID != this.initialPackage?.PackagePoolID
    );
  }

  PackageChanged(event) {
    this.rnsidebarService.setPendingChanges(this.canSubmit());
  }
}
