import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { NavigationService } from "../services/navigation/navigation.service";
import { AuthenticationService } from "./authentication.service";

@Injectable({
  providedIn: "root",
})
export class AlreadyLoggedInAuthGuard {
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private navService: NavigationService,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (
      this.routeExceptions.some(function (v) {
        return (
          state.url.toLocaleLowerCase().indexOf(v.toLocaleLowerCase()) >= 0
        );
      })
    ) {
      return true;
    }

    if (!this.authService.userIsLoggedIn()) {
      return true;
    }

    return this.router.parseUrl(this.navService.GetStartPageUrl());
  }

  // routes to ignore guard
  private routeExceptions: string[] = ["/setpassword", "/forgot"];
}
