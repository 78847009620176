<div class="promoContainer futureBill">
  <div>
    <span class="promo-label">Promo Code&nbsp;</span>
    <br />
    <div class="form-group disp-inl-flex">
      <input
        id="promoTextbox"
        maxlength="25"
        autocomplete="off"
        (focus)="error = false"
        [ngClass]="{ 'sharp-angles-error': error }"
        type="text"
        [(ngModel)]="inputPromoCode"
        placeholder="Promo Code"
        class="textbox-sharp-angles"
        (keyup)="onPromoChange()"
      />
      <button
        [disabled]="!inputPromoCode"
        type="button"
        (click)="validatePromoCode(inputPromoCode)"
        [ngClass]="
          promoCodeIsValid === false && wait === false
            ? 'blue-promo-apply-btn'
            : 'blue-promo-apply-btn blue-promo-apply-btn-default-pointer'
        "
      >
        <span class="applyPromo" *ngIf="!promoCodeIsValid && !wait">APPLY</span>
        <span *ngIf="wait === true"
          ><app-icon
            source="rn-circle-notch"
            imgClass="loading-spinner gray-icon "
          ></app-icon
        ></span>
        <span *ngIf="promoCodeIsValid === true"
          ><i class="fa fa-check promo-apply-check" aria-hidden="true"></i
        ></span>
      </button>
    </div>
  </div>
  <div class="red-help-block" *ngIf="error">
    Promo Code {{ inputPromoCode }} is not valid or has expired.
  </div>
</div>
