import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  NgZone,
} from "@angular/core";
import { processButtonStateTypes } from "./process-button-state";
import { ProcessButtonConfiguration } from "./process-button-configuration";
import { OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { ChangeDetectorRef } from "@angular/core";
import { EnvService } from "../../../../core/services/env.service";

@Component({
  selector: "app-process-button",
  templateUrl: "./process-button.component.html",
  styleUrls: ["./process-button.component.scss"],
})
export class ProcessButtonComponent implements OnInit, OnDestroy {
  tooltipText = "test";
  tooltipEscape = false;
  tooltipPosition = "right";
  tooltipEnabled = false;
  assetUrl: string;

  buttonClass = "";
  buttonDisabled = false;
  buttonText = "";
  buttonIcon = "";
  buttonImage = "";
  imageClass = "";

  @Input() set Configuration(config: ProcessButtonConfiguration) {
    this.ButtonConfiguration = config;
    this.subscriptions.push(
      this.ButtonConfiguration.State.subscribe((s) => {
        this.ngZone.run(() => {
          const setTimer =
            this._processState === processButtonStateTypes.processing &&
            s !== processButtonStateTypes.normal;
          this.updateState(s);
          if (setTimer && this.ButtonConfiguration.AutoReset) {
            setTimeout(() => {
              this.updateState(processButtonStateTypes.normal);
              this.updateState(this._processState);
            }, this.ButtonConfiguration.ProcessCompleteTimeoutPeriod);
          }

          this.ButtonConfiguration.TooltipText.subscribe((t) => {
            //this.tooltipText = t;
            //this.tooltipEnabled = this.tooltipText && this.tooltipText !== '';
          });
          this.ButtonConfiguration.TooltipEscape.subscribe((e) => {
            this.tooltipEscape = e;
          });
          this.ButtonConfiguration.TooltipEnabled.subscribe((e) => {
            this.tooltipEnabled = e;
          });
          this.ButtonConfiguration.TooltipPosition.subscribe((p) => {
            this.tooltipPosition = p;
          });
          this.cd.detectChanges();
        });
      }),
    );
  }
  @Output() ButtonClicked = new EventEmitter<string>();
  constructor(
    private ngZone: NgZone,
    private cd: ChangeDetectorRef,
  ) {
    this.assetUrl = EnvService.EnvVariables().assetUrl;
  }
  public ButtonConfiguration: ProcessButtonConfiguration;
  private _processState = processButtonStateTypes.normal;

  public currentButtonText(): string {
    switch (this._processState) {
      case processButtonStateTypes.normal:
        return this.ButtonConfiguration.NormalButtonText;
      case processButtonStateTypes.processed:
        return this.ButtonConfiguration.ProcessedButtonText &&
          this.ButtonConfiguration.ProcessedButtonText !== ""
          ? this.ButtonConfiguration.ProcessedButtonText
          : "Processed";
      case processButtonStateTypes.processing:
        return this.ButtonConfiguration.ProcessingButtonText &&
          this.ButtonConfiguration.ProcessingButtonText !== ""
          ? this.ButtonConfiguration.ProcessingButtonText
          : "Processing...";
      case processButtonStateTypes.succeeded:
        return this.ButtonConfiguration.SucceededButtonText &&
          this.ButtonConfiguration.SucceededButtonText !== ""
          ? this.ButtonConfiguration.SucceededButtonText
          : "Succeeded";
      case processButtonStateTypes.failed:
        return this.ButtonConfiguration.FailedButtonText &&
          this.ButtonConfiguration.FailedButtonText !== ""
          ? this.ButtonConfiguration.FailedButtonText
          : "Failed";
      default:
        return "";
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.buttonClass = this.GetButtonClass();
    this.buttonDisabled = this.ButtonConfiguration.GetIsDisabled();
    this.buttonText = this.currentButtonText();
  }

  private updateState(state: processButtonStateTypes) {
    this._processState = state;
    this.buttonDisabled = state !== processButtonStateTypes.normal;
    this.buttonText = this.currentButtonText();
    this.buttonIcon = this.currentIcon();
    this.buttonImage = this.currentImage();
    this.imageClass = this.currentImgClass();
  }

  public buttonClicked(event) {
    if (this.ButtonConfiguration.StopPropagation) {
      event.stopPropagation();
    }
    this._processState = processButtonStateTypes.processing;
    if (this.ButtonClicked) {
      this.ButtonClicked.emit(this.ButtonConfiguration.ProcessID);
    }
    this.updateState(this._processState);
  }

  public currentIcon(): string {
    switch (this._processState) {
      case processButtonStateTypes.normal:
        return this.ButtonConfiguration.IconNormalClass;
      case processButtonStateTypes.processing:
        return this.ButtonConfiguration.IconProcessingClass
          ? this.ButtonConfiguration.IconProcessingClass
          : "fas fa-circle-notch fa-spin";
      case processButtonStateTypes.succeeded:
        return this.ButtonConfiguration.IconSucceededClass
          ? this.ButtonConfiguration.IconSucceededClass
          : "fa fa-check";
      default:
        return "";
    }
  }

  public currentImage(): string {
    switch (this._processState) {
      case processButtonStateTypes.normal:
        return this.ButtonConfiguration.IconNormalImage;
      case processButtonStateTypes.processing:
        return this.ButtonConfiguration.IconProcessingImage
          ? this.ButtonConfiguration.IconProcessingImage
          : "";
      case processButtonStateTypes.processed:
        return this.ButtonConfiguration.IconProcessedImage
          ? this.ButtonConfiguration.IconProcessedImage
          : "";
      case processButtonStateTypes.succeeded:
        return this.ButtonConfiguration.IconSucceededImage
          ? this.ButtonConfiguration.IconSucceededImage
          : "";
      case processButtonStateTypes.failed:
        return this.ButtonConfiguration.IconFailedImage
          ? this.ButtonConfiguration.IconFailedImage
          : "";
      default:
        return "";
    }
  }

  public currentImgClass(): string {
    switch (this._processState) {
      case processButtonStateTypes.normal:
        return this.ButtonConfiguration.IconNormalClass;
      case processButtonStateTypes.processing:
        return this.ButtonConfiguration.IconProcessingClass
          ? this.ButtonConfiguration.IconProcessingClass
          : "";
      case processButtonStateTypes.processed:
        return this.ButtonConfiguration.IconProcessedClass
          ? this.ButtonConfiguration.IconProcessedClass
          : "";
      case processButtonStateTypes.succeeded:
        return this.ButtonConfiguration.IconSucceededClass
          ? this.ButtonConfiguration.IconSucceededClass
          : "";
      case processButtonStateTypes.failed:
        return this.ButtonConfiguration.IconFailedClass
          ? this.ButtonConfiguration.IconFailedClass
          : "";
      default:
        return "";
    }
  }

  public GetButtonClass(): string {
    if (
      this.ButtonConfiguration.ButtonClass &&
      this.ButtonConfiguration.ButtonClass !== ""
    ) {
      return this.ButtonConfiguration.ButtonClass;
    }

    return "rn-btn-tertiary-action";
  }

  public GetButtonProcessStateClass(): string {
    switch (this._processState) {
      case processButtonStateTypes.failed:
        return "rnaction-failed";
      case processButtonStateTypes.succeeded:
        return "rnaction-success";
      default:
        return "";
    }
  }

  private subscriptions: Subscription[] = [];
}
