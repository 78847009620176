import { GridColumnBaseConfiguration } from "./grid-column-base-configuration";

export class GridMenuColumnConfiguration extends GridColumnBaseConfiguration {
  constructor(
    fieldName: string,
    title: string,
    classname: string,
    sortOrder: string,
  ) {
    super("menu", fieldName, title, classname, sortOrder);
    this.CustomDataRetrieval = (dataRow: any) => {
      return this.GetLinkText(dataRow);
    };
  }
  public LinkText: string;
  public GetCustomLinkText: (dataRow: any) => string;
  public GetAction: (dataRow: any) => void;
  public Target: string;
  public isFirstAppIcon: boolean;
  public firstAppiconSource: string;
  public firstMenuText: string;
  public firstAppIconClass: string;
  public iconClass: string;

  public GetLinkText(dataRow: any) {
    if (this.LinkText && this.LinkText !== "") {
      return this.LinkText;
    } else if (this.GetCustomLinkText) {
      return this.GetCustomLinkText(dataRow);
    }
    return dataRow[this.FieldName];
  }

  public TypeName(dataRow: any): string {
    return super.TypeName(dataRow);
  }
}
