import { Component, EventEmitter, OnInit, Input, Output } from "@angular/core";
import {
  ApplicationCard,
  RequestType,
} from "../../../../core/models/application-card.model";
import { LoggedInInfoService } from "../../../services/loggedInInfo/logged-in-info.service";
import * as dayjs from "dayjs";
import { GenericDialogConfiguration } from "../generic-dialog/generic-dialog-configuration";
import { NotificationDialogService } from "src/app/shared/services/notificationDialog/notification-dialog.service";

@Component({
  selector: "app-application-card",
  templateUrl: "./application-card.component.html",
  styleUrls: ["./application-card.component.scss"],
})
export class ApplicationCardComponent implements OnInit {
  public linkButtonDisabled: boolean;
  @Input() canUseNewTiles = false;
  @Input() card: ApplicationCard;
  @Input() userID: number;
  @Input() canUseOpenButton = false;
  @Output() installRequest = new EventEmitter<ApplicationCard>();
  @Output() uninstallRequest = new EventEmitter<ApplicationCard>();
  @Output() openRequest = new EventEmitter<ApplicationCard>();

  @Output() cardRequestEvent = new EventEmitter<ApplicationCard>();

  constructor(
    private loginInfoService: LoggedInInfoService,
    private notificationDialogService: NotificationDialogService,
  ) {}

  ngOnInit(): void {
    const loggedInUser = this.loginInfoService.GetLoggedInUser();
    if (!loggedInUser) {
      return;
    }
    this.linkButtonDisabled = false;
    this.loggedInUserID = loggedInUser.UserID;
    this.card.popoverText = this.popoverText();
  }

  showCard() {
    if (
      this.card.ProvisioningState === "InstallError" ||
      this.card.ProvisioningState === "RemoveError" ||
      this.card.ProvisioningState === "InstallFailure" ||
      this.card.ProvisioningState === "RemoveFailure"
    )
      return true;
  }

  install() {
    this.card.ProvisioningState = "Installing";
    this.card.requestType = RequestType.Install;
    this.cardRequest();
  }

  uninstall() {
    this.card.ProvisioningState = "Removing";
    this.card.requestType = RequestType.Uninstall;
    this.cardRequest();
    this.linkButtonDisabled = true;
  }

  confirmUninstall() {
    const confirmConfig = new GenericDialogConfiguration();
    confirmConfig.Title = "Confirm App Uninstall";
    confirmConfig.StyleClass = "confirmation";
    confirmConfig.ConfirmButtonText = "Confirm";
    confirmConfig.CancelButtonText = "Cancel";
    confirmConfig.CancelButtonStyleClass = "cancelButton";
    confirmConfig.MessageContainsHTML = true;
    confirmConfig.ConfirmButtonStyleClass = "primary";
    confirmConfig.showConfirmIcon = true;
    confirmConfig.DialogHeaderClass = "modal-header no-border";
    confirmConfig.DialogFooterCancelClass = "right-spacing";
    confirmConfig.Message =
      "<p>Are you sure you'd like to uninstall this App? You can reinstall it at another time.</p>";
    confirmConfig.Confirmed = () => {
      this.uninstall();
    };
    this.notificationDialogService.ShowConfirmation(confirmConfig);
  }

  getTooltip(card: ApplicationCard) {
    if (card.displayType() == "Installing") {
      return `Installing...(${card.percentComplete}%)`;
    } else if (card.displayType() == "Removing") {
      return `Removing...(${card.percentComplete}%)`;
    } else if (card.displayType() == "InstallFailure") {
      return "Sorry, we are not able to add the application at this time. Please contact Support at 1-888-417-4448 Ext. 2 for help.";
    } else if (card.displayType() == "RemoveFailure") {
      return "Sorry, we are not able to remove the application at this time. Please contact Support at 1-888-417-4448 Ext. 2 for help.";
    } else if (card.displayType() == "InstallError") {
      return "Install failed. Please try again.";
    } else if (card.displayType() == "RemoveError") {
      return "Remove failed. Please try again.";
    }
    return null;
  }

  open() {
    this.card.isOpening = true;
    this.card.requestType = RequestType.Open;
    this.cardRequest();
  }

  canInstall() {
    return !this.card.BlockingFlavorId;
  }

  cardRequest() {
    this.cardRequestEvent.emit(this.card);
  }

  showOpenButton(): boolean {
    return this.card.RemoteAppEnabled;
  }

  formatAppGroupName(app: string): string {
    return app ? app.replace(/\b\d{4}\b/, "").trim() : "";
  }

  getUninstallWidth(): string {
    return this.showOpenButton() ? "98px" : "200px";
  }

  popoverText(): string {
    if (!this.card) {
      return "";
    }
    let result = `<b>${this.card.Name}</b><br /> ${this.card?.FlavorString}`;
    if (this.card.Installed && this.card.InstalledDate) {
      result += `<br /><br />INSTALLED ON: ${dayjs(this.card.InstalledDate).format("MM/DD/YYYY")}`;
    }
    return result;
  }
  private loggedInUserID: number;
}
