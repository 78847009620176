import { Injectable } from "@angular/core";
import { EnvVariables } from "../models/env.variables";

@Injectable({
  providedIn: "root",
})
export class EnvService {
  constructor() {}

  static EnvVariables(): EnvVariables {
    const browserWindow = window || {};
    const browserWindowEnv = browserWindow["__env"] || {};

    const result = new EnvVariables();
    const env = window["__env"];
    result.baseUrl2 = env.api2Url;
    result.version = env.version;
    result.enableUserDemo = env.enableUserDemo;
    result.beginYearRange = env.beginYearRange;
    result.assetUrl = env.assetUrl;
    result.instrumentationKey = env.instrumentationKey;
    result.enableDebug = env.enableDebug;
    result.enableDatadog = env.enableDatadog;
    result.envPrefix = env.envPrefix ?? "dev";
    result.chatType = env.chatType ?? "Genesys";
    result.chatUrl = env.chatUrl;
    result.chatEnvironment = env.chatEnvironment;
    result.chatDeploymentId = env.chatDeploymentId;
    result.datadogApplicationId = env.datadogApplicationId;
    result.datadogClientToken = env.datadogClientToken;
    result.datadogSite = env.datadogSite;
    result.datadogService = env.datadogService;
    result.datadogEnv = env.datadogEnv;
    result.datadogTracingUrl = env.datadogTracingUrl;
    result.datadogPropagatorTypes = env.datadogPropagatorTypes;
    return result;
  }
}
