<ng-container [formGroup]="parentForm">
  <div class="row">
    <div class="col-12">
      <label for="country">Country</label><br />
      <p-dropdown
        [attr.disabled]="isReadOnly"
        dropdownIcon="fa fa-caret-down"
        id="country"
        name="country"
        formControlName="country"
        [options]="countries"
        optionLabel="value"
        (keydown)="onDropdownKeydown($event)"
        (onChange)="onChangeCountry(false, $event)"
        panelStyleClass="highlight-yellow"
        class="width-100-percent withoutMarginDropdown"
      ></p-dropdown>
    </div>
  </div>

  <div class="row p-mt-3">
    <div class="col-12">
      <div
        class="p-field"
        [ngClass]="{
          'has-error': !address1?.valid && (submitted || address1?.touched)
        }"
      >
        <label for="address1">Street Address</label><br />
        <input
          [readonly]="isReadOnly"
          appTrimInput
          id="address1"
          name="address1"
          formControlName="address1"
          class="ui-inputtext form-control"
          placeholder="Street Address"
          pInputText
          maxlength="255"
          (focus)="$event.target.select()"
          data-testid="businessAddressAddress1"
        />
        <div *ngIf="submitted || address1?.touched">
          <div
            class="help-block pt-1"
            *ngIf="address1?.errors?.required || address1?.errors?.whitespace"
          >
            Required field
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="p-field">
        <input
          [readonly]="isReadOnly"
          id="address2"
          name="address2"
          formControlName="address2"
          class="ui-inputtext form-control"
          placeholder="Apartment, Suite, etc. (optional)"
          maxlength="255"
          (focus)="$event.target.select()"
          data-testid="businessAddressAddress2"
          pInputText
        />
      </div>
    </div>
  </div>

  <div
    class="row"
    [class.cityDiv]="
      country?.value?.value !== 'United States' &&
      country?.value?.value !== 'Canada'
    "
  >
    <div class="col-12">
      <div
        class="p-field"
        [ngClass]="{
          'has-error': !city?.valid && (submitted || city?.touched)
        }"
      >
        <label for="City">City</label><br />
        <input
          [readonly]="isReadOnly"
          appTrimInput
          id="city"
          name="city"
          class="form-control"
          formControlName="city"
          placeholder="City"
          pInputText
          (focus)="$event.target.select()"
          maxlength="40"
          data-testid="businessAddressCity"
        />
        <div *ngIf="submitted || city?.touched">
          <div
            class="help-block pt-1"
            *ngIf="city.errors?.required || city.errors?.whitespace"
          >
            Required field
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="row"
    [class.zipCodeDiv]="
      country?.value?.value !== 'United States' &&
      country?.value?.value !== 'Canada'
    "
  >
    <div class="col-6">
      <div
        class="p-field"
        [ngClass]="{
          'has-error':
            (!postalCode?.valid || zipCodeBadInput) &&
            (submitted || postalCode?.touched)
        }"
      >
        <label for="postalCode">Zip Code</label><br />
        <input
          [readonly]="isReadOnly"
          id="postalCode"
          name="postalCode"
          class="form-control"
          formControlName="postalCode"
          placeholder="Zip Code"
          pInputText
          (blur)="onBlurZipCode()"
          (focus)="$event.target.select()"
          [pattern]="zipCodePattern"
          maxlength="20"
          data-testid="businessAddressZipCode"
        />
        <div *ngIf="submitted || postalCode?.touched">
          <div
            class="help-block pt-1"
            *ngIf="postalCode.errors?.required || postalCode.errors?.whitespace"
          >
            Required field
          </div>
          <div
            class="help-block pt-1"
            *ngIf="!zipCodeBadInput && postalCode.errors?.pattern"
          >
            Invalid zip code format
          </div>
          <div class="help-block pt-1" *ngIf="zipCodeBadInput">
            Invalid zip code
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="stateCombo"
      class="col-6"
      [class.stateDiv]="
        country?.value?.value !== 'United States' &&
        country?.value?.value !== 'Canada'
      "
    >
      <div
        class="p-field"
        [ngClass]="{
          'has-error': !state?.valid && (submitted || state?.touched)
        }"
      >
        <label for="state">State/Province</label><br />
        <p-dropdown
          dropdownIcon="fa fa-caret-down"
          class="withoutMarginDropdown"
          [attr.disabled]="isReadOnly"
          id="state"
          name="state"
          formControlName="state"
          [options]="states"
          [required]="stateCombo"
          placeholder="State/Province"
        ></p-dropdown>
        <div *ngIf="submitted || state?.touched">
          <div
            class="help-block pt-1"
            *ngIf="state.errors?.required || state.errors?.whitespace"
          >
            Required field
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="!stateCombo"
      class="col-6"
      [class.stateDiv]="
        country?.value?.value !== 'United States' &&
        country?.value?.value !== 'Canada'
      "
    >
      <div
        class="p-field"
        [ngClass]="{
          'has-error': !stateText?.valid && (submitted || stateText?.touched)
        }"
      >
        <label for="State/Province">State/Province</label><br />
        <input
          id="stateText"
          name="stateText"
          class="form-control"
          formControlName="stateText"
          placeholder="Province or Region"
          pInputText
          [required]="!stateCombo"
          maxlength="40"
        />
        <div *ngIf="submitted || stateText?.touched">
          <div
            class="help-block pt-1"
            *ngIf="stateText.errors?.required || stateText.errors?.whitespace"
          >
            Required field
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
