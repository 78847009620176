import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdminGuardService } from "./shared/security/admin-guard.service";
import { AlreadyLoggedInAuthGuard } from "./shared/security/already-logged-in-auth-guard";
import { AuthGuardService } from "./shared/security/auth.guard.service";
import { NotAuthorizedComponent } from "./shared/security/not-authorized/not-authorized.component";

const routes: Routes = [
  {
    path: "search",
    loadChildren: () =>
      import("./feature-modules/search/search.module").then(
        (m) => m.SearchModule,
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "intuitsetup",
    loadChildren: () =>
      import("./feature-modules/intuitsetup/intuitsetup.module").then(
        (m) => m.IntuitsetupModule,
      ),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./feature-modules/login/login.module").then((m) => m.LoginModule),
    canActivate: [AlreadyLoggedInAuthGuard],
  },
  {
    path: "organization",
    loadChildren: () =>
      import("./feature-modules/organization/organization.module").then(
        (m) => m.OrganizationModule,
      ),
    canActivate: [AuthGuardService, AdminGuardService],
  },
  {
    path: "user",
    loadChildren: () =>
      import("./feature-modules/user/user.module").then((m) => m.UserModule),
    canActivate: [AuthGuardService],
  },
  {
    path: "newwelcomewizard",
    loadChildren: () =>
      import("./feature-modules/newwelcomewizard/newwelcomewizard.module").then(
        (m) => m.NewwelcomewizardModule,
      ),
  },
  {
    path: "unauthorized",
    component: NotAuthorizedComponent,
  },
  {
    path: "**",
    redirectTo: "login",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppRoutingModule {}
