<form [formGroup]="parentForm">
  <fieldset>
    <div class="col-sm-12">
      <div class="row">
        <div class="col-sm-6">
          <div
            class="form-group"
            [ngClass]="{ 'has-error': !firstName.valid && firstName.touched }"
          >
            <label for="firstName">First Name</label>
            <div class="input-icons">
              <i *ngIf="showFieldIcons" class="fa fa-user icon"></i>
              <input
                id="firstName"
                type="text"
                [class.hideLeftIconPadding]="!showFieldIcons"
                class="form-control input-field"
                formControlName="firstName"
                placeholder="First Name"
              />
            </div>
            <div *ngIf="firstName.touched">
              <div class="help-block" *ngIf="firstName.errors?.required">
                Required field
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div
            class="form-group"
            [ngClass]="{ 'has-error': !lastName.valid && lastName.touched }"
          >
            <label for="lastName">Last Name</label>
            <div class="input-icons">
              <i *ngIf="showFieldIcons" class="fa fa-user icon"></i>
              <input
                id="lastName"
                type="text"
                [class.hideLeftIconPadding]="!showFieldIcons"
                class="form-control input-field"
                formControlName="lastName"
                placeholder="Last Name"
              />
            </div>
            <div *ngIf="lastName.touched">
              <div class="help-block" *ngIf="lastName.errors?.required">
                Required field
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-sm-5 col-md-4"
          [ngClass]="{
            'col-lg-3': !largerCountryCode,
            'col-lg-4': largerCountryCode
          }"
        >
          <div
            class="form-group"
            [ngClass]="{ 'has-error': !country.valid && country.touched }"
          >
            <label for="country">Country Code</label>
            <app-select
              componentId="country"
              [items]="countries"
              value="CountryCode"
              text="CountryName"
              [frmComponent]="parentForm"
              [selectedItem]="country.value"
              [showIcon]="showFieldIcons"
              [loading]="loadingCountries"
            >
            </app-select>
          </div>
        </div>
        <div class="col-sm-7 col-md-7 col-lg-8">
          <div class="row">
            <div class="col-sm-4 col-md-5 col-lg-5">
              <div
                class="form-group"
                [ngClass]="{ 'has-error': !areaCode.valid && areaCode.touched }"
              >
                <label for="areacode">Area Code</label>
                <div class="input-icons">
                  <i *ngIf="showFieldIcons" class="fa fa-user icon"></i>
                  <input
                    id="areacode"
                    placeholder="Area Code"
                    type="text"
                    (focus)="clearIfNeeded()"
                    [class.hideLeftIconPadding]="!showFieldIcons"
                    class="form-control input-field"
                    maxlength="3"
                    formControlName="areaCode"
                  />
                </div>
                <div *ngIf="areaCode.touched">
                  <div class="help-block" *ngIf="areaCode.errors?.required">
                    Required field
                  </div>
                  <div class="help-block" *ngIf="areaCode.errors?.pattern">
                    Only numbers are allowed
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-8 col-md-7 col-lg-7">
              <div
                class="form-group"
                [ngClass]="{
                  'has-error': !phoneNumber.valid && phoneNumber.touched
                }"
              >
                <label for="phonenumber">Phone Number</label>
                <div class="input-icons">
                  <i *ngIf="showFieldIcons" class="fa fa-user icon"></i>
                  <input
                    id="phonenumber"
                    placeholder="Phone Number"
                    type="text"
                    maxlength="10"
                    [class.hideLeftIconPadding]="!showFieldIcons"
                    class="form-control input-field"
                    formControlName="phoneNumber"
                  />
                </div>
                <div *ngIf="phoneNumber.touched">
                  <div class="help-block" *ngIf="phoneNumber.errors?.required">
                    Required field
                  </div>
                  <div class="help-block" *ngIf="phoneNumber.errors?.pattern">
                    Only numbers are allowed
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-8 emailDivision">
        <app-email-verfication
          [userDetailsForm]="parentForm"
          [inEditingMode]="true"
          [showIcon]="showFieldIcons"
          [hideLeftPadding]="!showFieldIcons"
          [profileId]="loggedInUser.userid"
          data-testid="createProfileEmail"
        >
        </app-email-verfication>
      </div>
    </div>
  </fieldset>
</form>
