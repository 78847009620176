import { Injectable } from "@angular/core";
import { TimeoutSettings } from "../../../../core/models/timeoutSettings";
import { OrganizationService } from "../../rnapi2-service/apis/api";
import { RnCommonSearch } from "../../rnapi2-service/models/models";

@Injectable({
  providedIn: "root",
})
export class ConfigurationLogicService {
  constructor(private organizationService: OrganizationService) {}

  GetTimeOutSettings(callbackFunction) {
    const payload: RnCommonSearch = {
      search:
        "apphub_idle_interval,apphub_idle_timeout_interval,apphub_keep_alive_interval",
    };
    this.organizationService
      .apiV2OrganizationsGetmultipleconfigurablevaluesPost(payload)
      .subscribe((response) => {
        const timeoutSettings = new TimeoutSettings();
        timeoutSettings.idleInterval =
          Number(response.data["apphub_idle_interval"] ?? "7200") ?? 7200;
        timeoutSettings.idleTimeoutInterval =
          Number(response.data["apphub_idle_timeout_interval"] ?? "300") ?? 300;
        timeoutSettings.keepAliveInterval =
          Number(response.data["apphub_keep_alive_interval"] ?? "120") ?? 120;
        callbackFunction(timeoutSettings);
      });
  }
}
