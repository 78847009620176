<div class="full-container-padding-side-15">
  <div class="flex-row m-t-md" *ngIf="IsUsingWelcomeWizard">
    <div class="col-sm-12 addUsersHeadingDivision">
      <h2 class="addUsersHeading">Add Users & Assign Packages</h2>
    </div>
    <span class="addUserText">
      It's time to add <b>Users</b> to your account. You can add as many users
      as you'd like and assign them to the appropriate roles within your
      organization, but only the users you assign a <b>Package</b> to will be
      able to access the remote desktop environment. Additional user changes,
      including adding or deleting users, can be made within the Admin Console.
    </span>
  </div>
  <div class="flex buttonRow">
    <span class="usersHeading">Users</span>
    <span class="pull-right addNewUserButton">
      <app-button
        id="btnCreateNewUser"
        label="ADD USER"
        showIcon="true"
        imgSource="rn-user-plus"
        *ngIf="isAddUserVisible"
        [disabled]="
          orgConfig
            | rnOrganizationConfig
              : 'initiatedbillingseparation'
              : workInProgress
        "
        [pTooltip]="
          orgConfig | rnOrganizationConfig: 'billingseparationtooltip'
        "
        [escape]="false"
        buttonStyle="primaryUserModule"
        noContentPadding="true"
        tooltipStyleClass="wideTooltipSm"
        (clickActionEmitter)="createNewUser()"
      >
      </app-button>
    </span>

    <!-- <app-button id="deleteUser"
                label="Delete User(s)"
                *ngIf="IsUsingWelcomeWizard | rnUserMgmt: 'isdeleteuservisible'"
                [disabled]="selectedUsers.length | rnUserMgmt: 'isdeleteuserdisabled': selectedUsers"
                buttonStyle="primary"
                (onClick)="previousPackageAction()">
    </app-button> -->

    <span class="pull-right deleteUserButton">
      <app-button
        id="btnGenerateReport"
        class="lmargin-auto"
        label="GENERATE REPORT"
        showIcon="true"
        imgSource="rn-file-text"
        *ngIf="orgProfile | rnUserMgmt: 'cangeneratereport'"
        buttonStyle="primaryUserModule"
        (clickActionEmitter)="generateReport()"
        showProcessing="true"
        noContentPadding="true"
        [processing]="generatingReport"
      >
      </app-button>
    </span>
    <span class="pull-right">
      <app-button
        id="btnSendPasswordReset"
        label="Reset Password(s)"
        imgSource="rn-lock-outline"
        *ngIf="IsUsingWelcomeWizard | rnUserMgmt: 'isresetpasswordvisible'"
        [disabled]="!(selectedUsers?.length | rnQuantityChange: 'hasquantity')"
        buttonStyle="primaryUserModule"
        noContentPadding="true"
        showIcon="true"
        (clickActionEmitter)="sendPasswordReset()"
        showProcessing="true"
        [processing]="sendingResetEmails"
      >
      </app-button>
    </span>
    <span
      pTooltip="{{
        selectedUsers?.length
          | rnUserMgmt: 'resetactivationtooltip' : selectedUsers
      }}"
      class="pull-right"
      tooltipStyleClass="wideTooltipMed"
      [escape]="false"
    >
      <app-button
        id="ResendActivationBtn"
        label="Resend Activation Email(s)"
        imgSource="rn-mail"
        *ngIf="IsUsingWelcomeWizard | rnUserMgmt: 'isresendwelcomeemailvisible'"
        [disabled]="
          selectedUsers?.length
            | rnUserMgmt: 'isresendwelcomeemaildisabled' : selectedUsers
        "
        buttonStyle="primaryUserModule"
        (clickActionEmitter)="sendWelcomeEmail()"
        showProcessing="true"
        showIcon="true"
        noContentPadding="true"
        [processing]="resendingActivationEmails"
      >
      </app-button>
    </span>
    <span class="pull-right">
      <app-button
        id="btnUnlockUsers"
        label="Unlock Account(s)"
        *ngIf="IsUsingWelcomeWizard | rnUserMgmt: 'isunlockaccountvisible'"
        [disabled]="!(selectedUsers?.length | rnQuantityChange: 'hasquantity')"
        buttonStyle="primaryUserModule"
        noContentPadding="true"
        (clickActionEmitter)="unlockUsers()"
      >
      </app-button>
    </span>

    <span class="pull-right deleteUserButton">
      <button
        id="deleteUser"
        *ngIf="IsUsingWelcomeWizard | rnUserMgmt: 'isdeleteuservisible'"
        data-testid="btnRemoveLicenses"
        [disabled]="
          selectedUsers?.length
            | rnUserMgmt: 'isdeleteuserdisabled' : selectedUsers
        "
        class="button-spaced btn-icon"
        style=""
        (click)="previousPackageAction()"
      >
        <app-icon source="rn-trash-2" [imgClass]="'deleteIcon'"></app-icon>
        &nbsp;DELETE
      </button>
    </span>
  </div>
  <div class="flex searchBox">
    <app-search-control
      #userSearchControl
      id="userSearchControl"
      class="col-xs-12 col-sm-12 col-md-6 col-lg-6"
      [Configuration]="searchControlConfiguration"
      (SearchOccurred)="searchOccurred($event)"
      [clearEvent]="clearSearch.asObservable()"
      [searchText]="searchText"
    ></app-search-control>
  </div>
  <div class="flex usersTable">
    <rn-grid
      class="flex-fill"
      [Configuration]="userListGridConfiguration"
      [UniqueId]="'UserID'"
      (rowSelected)="rowSelected($event)"
      (userRowClicked)="navigateToUser($event)"
      (falseyValueClicked)="copyActivationLinkClicked($event)"
      (actionButtonClicked)="actionClickHandler($event)"
      [clearSelected]="clearSelection.asObservable()"
    ></rn-grid>
  </div>
</div>
