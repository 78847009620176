<div
  class="modal-content"
  [ngClass]="{ custom_SidebarUserAction: customZoom }"
  style="height: 97vh"
>
  <div class="modal-header" style="height: 3%">
    <h3 class="info-modal-title">{{ dialogTitle }}</h3>
    <div>
      <button type="button"
              class="close pullUpperRight"
              (click)="dismiss()"
              aria-hidden="true">
        <span uib-tooltip="Close" tooltipPosition="top" pTooltip="Close">×</span>
      </button>
    </div>
  </div>
  <hr />
  <div style="height: 80%">
    <div *ngIf="!clientAccessReadOnly">
      <div
        class="row"
        style="
          height: 5%;
          min-height: 50px;
          padding-left: 15px;
          padding-bottom: 20px;
          margin-bottom: 10px;
        "
      >
        <div class="user-access-header-lines col-md-12">
          <div class="user-access-header-lines-prefix">User&nbsp;</div>
          <div>
            {{ accessMgmtConfig.UserProfile | rnUserProfile: "fullname" }}
          </div>
        </div>
      </div>
      <div
        class="row"
        style="
          height: 5%;
          min-height: 50px;
          padding-left: 15px;
          padding-bottom: 20px;
          margin-bottom: 10px;
        "
      >
        <div class="user-access-header-lines col-md-12">
          <div class="user-access-header-lines-prefix">Organization&nbsp;</div>
          <div>{{ accessMgmtConfig.UserProfile.OrganizationName }}</div>
        </div>
      </div>
      <div
        class="row"
        style="
          height: 5%;
          min-height: 50px;
          padding-left: 15px;
          padding-bottom: 10px;
          margin-bottom: 10px;
        "
      >
        <div class="user-access-header-lines col-md-12">
          <div class="user-access-header-lines-prefix ws-nowrap">
            Drive Mappings Available&nbsp;<span
              [ngClass]="{ 'negative-drive-count': availableDriveCount < 0 }"
            ></span>
            <app-icon
              source="rn-help"
              tooltipPosition="bottom"
              positionStyle="absolute"
              tooltipZIndex="20000"
              pTooltip="<span>Quantity includes external mappings as well as internal mappings shown below.</span>"
              [escape]="false"
            ></app-icon>
          </div>
          <div>{{ availableDriveCount }}&nbsp;</div>
        </div>
      </div>
    </div>
    <hr />
    <div style="height: 10%; padding-left: 15px" *ngIf="clientAccessReadOnly">
      <div style="padding-left: 17px">
        {{
          accessMgmtConfig.UserProfile | rnUserProfile: "accessmgmreadonlydesc"
        }}
      </div>
    </div>
    <div style="height: 95%; padding-left: 15px" *ngIf="!clientAccessReadOnly">
      <div class="client-access-error-panel">
        <span *ngIf="availableDriveCount === 0"
          >You have reached the limit of available drives.</span
        >
        <span *ngIf="availableDriveCount < 0"
          >You have exceeded the limit of available drives for this user. Please
          uncheck folders until you are within the limit.</span
        >
      </div>
      <div style="height: 10%" class="no-margins">
        You can modify this user's access to folders within their hosted
        desktop.
        <br />
        Check the box to grant access, and uncheck the box to remove access.
      </div>
      <div style="height: 55%" class="treeBox">
        <div class="client-access-treeview" style="height: 100%">
          <p-tree
            [(selection)]="selectedNodesArray"
            [value]="treeNodes"
            selectionMode="checkbox"
            (onNodeSelect)="nodeSelect($event)"
            (onNodeUnselect)="nodeSelect($event)"
            [style]="{ height: '100%', width: '100%', overflow: 'auto' }"
          >
            <!-- Using NGTemplate here to override the label due to treeNodes displaying the child count after the label OSFB-214-->
            <ng-template let-node pTemplate="default">
              {{ node.label }}
            </ng-template>
          </p-tree>
        </div>
      </div>
    </div>
    <div
      class="client-access-treeview client-access-treeview-readonly"
      *ngIf="clientAccessReadOnly"
      style="height: 85%"
    >
      <p-tree
        [style]="{ height: '100%', width: '100%', overflow: 'hidden' }"
        [(selection)]="selectedNodesArray"
        [value]="treeNodes"
      >
        <!-- Using NGTemplate here to override the label due to treeNodes displaying the child count after the label OSFB-214-->
        <ng-template let-node pTemplate="default">
          {{ node.label }}
        </ng-template>
      </p-tree>
    </div>
  </div>
  <div class="btn_wrapper">
    <app-button
      id="btnCancel"
      [label]="clientAccessReadOnly ? 'CLOSE' : 'CANCEL'"
      buttonStyle="addUserTeritary"
      noContentPadding="true"
      (clickActionEmitter)="dismiss()"
    >
    </app-button>
    <app-button
      id="btnSubmit"
      label="Save"
      *ngIf="!clientAccessReadOnly"
      [disabled]="!canSubmit || !selectedNodesArray.length"
      showProcessing="true"
      [processing]="loading"
      noContentPadding="true"
      buttonStyle="primary"
      (clickActionEmitter)="saveChanges()"
    >
    </app-button>
  </div>
</div>
