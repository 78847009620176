<div
  data-testid="QtySection"
  class="disp-inl-flex"
  tooltipPosition="bottom"
  positionStyle="absolute"
  tooltipZIndex="20000"
  class="quantityGroup"
>
  <div class="row">
    <div class="col-4">
      <button
        class="button button-small quantityControls"
        [ngClass]="btnStyleClass"
        [disabled]="this.QtyControlsDisabled"
        #decreaseBtn
        (click)="changeQuantity(-1)"
      >
        <i class="fa fa-minus" data-testid="btnQuantityControlDecrease"></i>
      </button>
    </div>
    <div class="col-4">
      <span class="outerBorder unselectable">
        <input
          type="number"
          #edit
          class="quantityValue"
          [ngClass]="inputStyleClass"
          [disabled]="this.QtyControlsDisabled || addDisabled"
          [(ngModel)]="selectedValue"
          [readonly]="isReadOnly"
          (blur)="onInputBlur($event)"
          (keypress)="disallowNonNumbers($event)"
          (paste)="disallowPaste()"
          data-testid="inputQuantityControl"
        />
      </span>
    </div>
    <div class="col-4">
      <div
        [tooltipDisabled]="!(disabled || addDisabled)"
        tooltipPosition="bottom"
        positionStyle="absolute"
        tooltipZIndex="20000"
        [pTooltip]="tooltipText"
        [escape]="true"
      >
        <button
          class="button button-small quantityControls"
          [ngClass]="btnStyleClass"
          #increaseBtn
          (click)="changeQuantity(1)"
          [disabled]="addDisabled"
          data-testid="btnQuantityControlIncrease"
        >
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>
  </div>
</div>
