<form *ngIf="email" [formGroup]="userDetailsForm">
  <div
    class="p-b-sm"
    [ngClass]="{
      'has-error': !email.valid && email.touched && !pendingvalidation,
      'has-pending-validation': email.touched && pendingvalidation,
      'p-field': location === 'addUser'
    }"
  >
    <!-- <label for="email">Email Address</label> -->
    <label
      for="emailInput"
      *ngIf="location === 'editUser'"
      class="userInfoCategory"
      >{{ emailLabel }}</label
    >
    <label for="emailInput" *ngIf="location === 'addUser'">{{
      emailLabel
    }}</label>
    <div class="showTitleText">
      <app-label
        *ngIf="showStaticEmail"
        [value]="userProfile?.EmailAddress"
        [cssClass]="labelClass"
        [truncateSize]="32"
      >
      </app-label>
    </div>
    <div *ngIf="showIcon" class="input-icons">
      <i class="fa fa-user icon"></i>
      <input
        id="emailInput"
        data-testid="emailInput"
        placeholder="Email Address"
        type="text"
        appTrimInput
        *ngIf="inEditingMode"
        class="form-control ui-inputtext input-field"
        [ngClass]="{ 'edit-form-control': location === 'editUser' }"
        formControlName="email"
        maxlength="75"
        (blur)="onBlurEmail()"
        (focus)="$event.target.select()"
      />
    </div>
    <div *ngIf="!showIcon">
      <input
        id="emailInput"
        data-testid="emailInput"
        placeholder="Email Address"
        type="text"
        appTrimInput
        *ngIf="inEditingMode"
        class="form-control ui-inputtext input-field + {{ emailInputClass }}"
        [ngClass]="{
          'edit-form-control': location === 'editUser',
          hideLeftIconPadding: hideLeftPadding
        }"
        formControlName="email"
        maxlength="75"
        (blur)="onBlurEmail()"
        (focus)="$event.target.select()"
      />
    </div>
    <div
      data-testid="emailErrors"
      *ngIf="
        !email.valid && email.touched && !pendingvalidation && inEditingMode
      "
    >
      <div
        data-testid="emailRequiredError"
        class="help-block"
        *ngIf="email.errors?.required"
      >
        Required field
      </div>
      <div
        data-testid="domainError"
        class="help-block"
        *ngIf="email.errors?.allowedemail"
      >
        'rightnetworks' is not a valid domain
      </div>
      <div
        data-testid="invalidEmailError"
        class="help-block"
        *ngIf="email.errors?.pattern"
      >
        Invalid email
      </div>
      <div
        data-testid="exsistingEmailError"
        class="help-block"
        *ngIf="email.errors?.nonspecific"
      >
        This email address is already in use
      </div>
    </div>
    <div *ngIf="email.touched && pendingvalidation && inEditingMode">
      <div
        data-testid="emailCheck"
        class="help-block"
        *ngIf="pendingvalidation"
      >
        Checking if email is unique...
        <app-icon
          data-testid="whiteLoadingSpinner"
          source="rn-circle-notch"
          imgClass="loading-spinner white-icon "
        ></app-icon>
      </div>
    </div>
  </div>
</form>
