<div class="row">
  <div class="col-md-5 detail-container">
    <div data-testid="licenseTypeHeader" class="license-type-header">
      <span class="license-card-headline">{{
        SelectedLicenseType.PackageName
      }}</span>
      <div class="license-type-desc license-card-desc">
        {{ SelectedLicenseType.PackageDescHtml }}
      </div>
    </div>
    <div class="license-detail-form-container">
      <form name="licenseForm" #licenseForm="ngForm" class="m-t-25" novalidate>
        <div class="row">
          <div class="license-detail-country-container">
            <label for="qbCountry" class="body-text">Country:</label>
            &nbsp;
            <span class="body-text">US</span>
          </div>
        </div>
        <div class="row">
          <div
            class="form-group col-sm-12"
            [ngClass]="{
              'has-error':
                subscribeVersion?.invalid &&
                (licenseForm.submitted || subscribeVersion?.touched)
            }"
          >
            <label for="subscribeVersion" class="body-text">Version</label>
            <p-dropdown
              id="subscribeVersion"
              data-testid="subscribeVersion"
              name="subscribeVersion"
              dropdownIcon="fa fa-caret-down"
              #subscribeVersion="ngModel"
              [options]="versionInfoSelect"
              [(ngModel)]="formData.qbVersion"
              (onChange)="changeQbVersion()"
              appendTo="body"
              required
            >
            </p-dropdown>
            <div *ngIf="licenseForm.submitted || subscribeVersion?.touched">
              <div
                class="help-block"
                *ngIf="subscribeVersion?.errors?.required"
              >
                Required field
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="form-group col-sm-12"
            [ngClass]="{
              'has-error':
                subscribeEdition?.invalid &&
                (licenseForm.submitted || subscribeEdition.touched)
            }"
          >
            <label for="subscribeEdition" class="body-text"
              >Edition
              <app-icon
                data-testid="editionIcon"
                source="rn-help"
                tooltip-placement="top"
                escape="false"
                pTooltip="{{ editionTooltip }}"
                [escape]="false"
              ></app-icon
            ></label>
            <p-dropdown
              id="subscribeEdition"
              data-testid="subscribeEdition"
              name="subscribeEdition"
              #subscribeEdition="ngModel"
              dropdownIcon="fa fa-caret-down"
              [options]="editionInfoSelect"
              [(ngModel)]="formData.qbEdition"
              (onChange)="changeQbEdition()"
              appendTo="body"
              required
            >
            </p-dropdown>
            <div *ngIf="licenseForm.submitted || subscribeEdition?.touched">
              <div
                class="help-block"
                *ngIf="subscribeEdition?.errors?.required"
              >
                Required field
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="showQuantityEdit()" class="row">
          <div class="form-group col-sm-12">
            <label for="leaseQuantity" class="body-text">Quantity </label>
            <app-quantity-control
              id="leaseQuantity"
              name="leaseQuantity"
              #leaseQuantity
              [(ngModel)]="formData.quantity"
              [disabled]="!formData.qbEdition"
              DisallowInput="false"
              [MinValue]="1"
            ></app-quantity-control>
          </div>
        </div>
        <div *ngIf="!showQuantityEdit()" class="row">
          <div
            class="form-group col-sm-12"
            [ngClass]="{
              'has-error':
                subscribeQuantity?.invalid &&
                (licenseForm.submitted || subscribeQuantity?.touched)
            }"
          >
            <label for="subscribeQuantity" class="body-text"
              >Number of Users
              <app-icon
                data-testid="NumOfUsersIcon"
                source="rn-help"
                tooltipPlacement="right"
                pTooltip="<span data-testid='tooltipMsg'>The number of users that are allowed to simultaneously access QuickBooks using this license. It's less expensive to purchase a multi-user license than the equivalent number of single-user licenses.</span>"
                [escape]="false"
              ></app-icon
            ></label>
            <p-dropdown
              id="subscribeQuantity"
              data-testid="subscribeQuantity"
              name="subscribeQuantity"
              #subscribeQuantity="ngModel"
              dropdownIcon="fa fa-caret-down"
              [options]="quantityInfoSelect"
              [(ngModel)]="formData.userCount"
              (onChange)="changeQbQuantity()"
              appendTo="body"
              required
            >
            </p-dropdown>
            <div *ngIf="licenseForm.submitted || subscribeQuantity?.touched">
              <div
                class="help-block"
                *ngIf="subscribeQuantity.errors?.required"
              >
                Required field
              </div>
              <div class="help-block" *ngIf="subscribeQuantity.errors?.pattern">
                Invalid license string
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="licensePurchConfig?.selectedOrg.HasClientOrgs">
          <div class="form-group col-sm-12">
            <label for="quantity" id="organizationLabel" class="body-text"
              >Which organization should get this license?</label
            >
            <br />
            <input
              data-testid="subscribeThisOrgRadio"
              id="subscribeThisOrgRadio"
              class="dest-org-radio"
              type="radio"
              [disabled]="orgRadioIsDisabled()"
              name="subscribeThisOrg"
              [(ngModel)]="formData.destOrgType"
              required
              value="thisorg"
            /><span class="inputLabel"
              >&nbsp;{{ licensePurchConfig?.selectedOrg.Name }} (My
              organization)</span
            >
            <br />
            <input
              data-testid="subscribeSubOrgRadio"
              id="subscribeSubOrgRadio"
              class="dest-org-radio"
              type="radio"
              [disabled]="orgRadioIsDisabled()"
              name="subscribeSubOrg"
              [(ngModel)]="formData.destOrgType"
              required
              value="subOrg"
            /><span class="inputLabel">&nbsp;A client organization</span>
            <div class="row">
              <div class="form-group col-sm-12" *ngIf="showOrgSelection()">
                <app-org-lookup
                  id="subscribedestOrgType"
                  name="subscribedestOrgType"
                  placeholderText="Search"
                  [(ngModel)]="formData.dest"
                  #subscribedestOrgType="ngModel"
                  [selectedOrg]="licensePurchConfig?.selectedOrg"
                  required="true"
                  [disabled]="formData.destOrgType !== 'subOrg'"
                ></app-org-lookup>
              </div>
              <div
                class="col-sm-12 height-28 clientData"
                *ngIf="showRemoveOrgSelection()"
              >
                <span class="clientName">{{ formData.dest.Name }}</span
                >&nbsp;<span class="pull-right">
                  <button
                    id="deleteClient"
                    data-testid="btnRemoveClient"
                    class="button-spaced btn-icon"
                    (click)="removeSelectedOrg()"
                  >
                    <app-icon
                      source="rn-trash-2"
                      [imgClass]="'deleteIcon'"
                    ></app-icon>
                    &nbsp;REMOVE
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="col-md-1"></div>
  <div class="col-md-5 license-order-summary-container">
    <span class="order-summary">Order Summary</span>
    <app-license-purchase-items
      [styleClass]="'orderSummary'"
      [purchases]="purchases"
      [licenseConfig]="licensePurchConfig"
    ></app-license-purchase-items>
  </div>
</div>
