import { GridColumnBaseConfiguration } from "./grid-column-base-configuration";

export class GridMultiValueColumnConfiguration extends GridColumnBaseConfiguration {
  public fieldNames: string[];
  public classNames: string[];
  public isLink = false;
  public GetHref: (dataRow: any) => string;

  constructor(
    fieldNames: string[],
    title: string,
    classnames: string[],
    sortOrder: string,
    islink = false,
  ) {
    super("multivalue", fieldNames[0], title, classnames[0], sortOrder);
    this.fieldNames = fieldNames;
    this.classNames = classnames;
    this.isLink = islink;
  }

  public TypeName(): string {
    return "multivalue";
  }
}
