import { Injectable } from "@angular/core";
import { LoggedInInfoService } from "../loggedInInfo/logged-in-info.service";
import { Router } from "@angular/router";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { OrganizationService, UserService } from "../rnapi2-service/apis/api";
import { Subscription } from "rxjs";
import { DialogTrackingService } from "../dialog/dialog-tracking.service";
import { NotificationDialogService } from "../notificationDialog/notification-dialog.service";

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  private downloadSetupDialogRef: DynamicDialogRef;
  constructor(
    private loggedInInfoService: LoggedInInfoService,
    public dialogService: DialogService,
    public userService: UserService,
    private router: Router,
    private dialogTrackService: DialogTrackingService,
    private notificationDialogService: NotificationDialogService,
    private orgService: OrganizationService,
  ) {}

  public async GoHome(): Promise<void> {
    const loggedInUser = this.loggedInInfoService.GetLoggedInUser();
    if (loggedInUser) {
      if (loggedInUser.IsInternal()) {
        await this.navigateToUrl("search");
      } else {
        if (
          !loggedInUser.ProfileCreated &&
          this.loggedInInfoService.GetLoggedInUserOrg().OrgStateName !==
            "Maintenance"
        ) {
          await this.navigateToUrl("profile/createprofile");
        } else if (
          this.loggedInInfoService.loggedInUserHasRights(["VIEWORGDET"]) &&
          (loggedInUser.AssignedPackage == "" ||
            loggedInUser.AssignedPackage.toLowerCase() == "no package")
        ) {
          await this.OpenOrganization(loggedInUser.OrganizationID);
        } else {
          await this.OpenUser(loggedInUser.UserID, loggedInUser.OrganizationID);
        }
      }
      return;
    }
    await this.GoToLoginPage();
  }

  public GetStartPageUrl(): string {
    const loggedInUser = this.loggedInInfoService.GetLoggedInUser();
    const loggedInUserOrg = this.loggedInInfoService.GetLoggedInUserOrg();
    if (loggedInUser) {
      if (loggedInUser.IsInternal()) {
        return "/search";
      } else {
        if (
          !loggedInUser.ProfileCreated &&
          loggedInUserOrg.OrgStateName !== "Maintenance"
        ) {
          return "/newwelcomewizard/welcomewizard";
        } else if (
          loggedInUser.AssignedPackage &&
          loggedInUser.AssignedPackage !== "" &&
          loggedInUserOrg.OrgStateName !== "Maintenance" &&
          loggedInUserOrg.OrgStateName !== "Suspended"
        ) {
          return (
            "user/" +
            loggedInUser.UserID.toString() +
            "?orgId=" +
            loggedInUser.OrganizationID.toString()
          );
        } else {
          if (this.loggedInInfoService.loggedInUserHasRights(["VIEWORGDET"])) {
            return "organization/" + loggedInUser.OrganizationID.toString();
          } else {
            return (
              "user/" +
              loggedInUser.UserID.toString() +
              "?orgId=" +
              loggedInUser.OrganizationID.toString()
            );
          }
        }
      }
    }
    return "/login/login";
  }
  public async GoToStartPage(): Promise<void> {
    const loggedInUser = this.loggedInInfoService.GetLoggedInUser();
    const loggedInUserOrg = this.loggedInInfoService.GetLoggedInUserOrg();
    if (loggedInUser) {
      if (loggedInUser.IsInternal()) {
        await this.navigateToUrl("search");
      } else {
        // normally (as long as the org isn't in maintenance mode) the user is brought to create profile
        // for OSFB-1, they'll always be brought to the WW to set their PIN
        if (
          !loggedInUser.ProfileCreated &&
          loggedInUserOrg.OrgStateName !== "Maintenance"
        ) {
          await this.navigateToUrl("newwelcomewizard/welcomewizard");
        } else {
          const loggedInUserOrgConfig =
            this.loggedInInfoService.GetLoggedInUserOrgConfig();
          // AP-236 - I don't know why, but for some reason, this loggedInUserOrg has Status set,
          // but not OrgStateName set, so I'm just checking both because I don't have time to figure out what is supposed to get set in here.
          const goToOrgForBillingSepInitiated =
            loggedInUserOrgConfig.InitiatedBillingSeparation &&
            this.loggedInInfoService.loggedInUserHasRights(["INITBILSEP"]);
          if (
            loggedInUser.AssignedPackage &&
            loggedInUser.AssignedPackage !== "" &&
            !goToOrgForBillingSepInitiated &&
            loggedInUserOrg.OrgStateName !== "Maintenance" &&
            loggedInUserOrg.OrgStateName !== "Suspended" &&
            loggedInUserOrg.Status !== "Suspended" &&
            loggedInUserOrg.Status !== "Maintenance"
          ) {
            await this.OpenUser(
              loggedInUser.UserID,
              loggedInUser.OrganizationID,
            );
          } else {
            if (
              this.loggedInInfoService.loggedInUserHasRights(["VIEWORGDET"])
            ) {
              await this.OpenOrganization(loggedInUser.OrganizationID);
            } else {
              await this.OpenUser(
                loggedInUser.UserID,
                loggedInUser.OrganizationID,
              );
            }
          }
        }
      }
      return;
    }
    await this.GoToLoginPage();
  }

  public async GoToLoginPage(): Promise<void> {
    await this.navigateToUrl("login/login");    
  }

  public async GoToLoginPageAndLogout(): Promise<void> {
    this.router.navigate(['login'], { queryParams: { logout: true } });
  }

  public async navigateToWelcomeWizardLanding(): Promise<void> {
    await this.navigateToUrl("welcomewizard/landing");
  }

  public async OpenOrganization(
    orgID: number,
    subOrgID?: number,
    activeTabName?: string,
  ) {
    const url = this.GetOpenOrgUserUrl(orgID, subOrgID, activeTabName);
    await this.navigateToUrl(url);
  }

  public GetOpenOrgUserUrl(
    orgID: number,
    subOrgID?: number,
    activeTabName?: string,
  ) {
    let url =
      "organization/" +
      orgID.toString() +
      "?subOrgId=" +
      (subOrgID ? subOrgID.toString() : orgID.toString());
    if (activeTabName && activeTabName !== "") {
      url += "&activeTab=" + activeTabName;
    } else {
      url += "&activeTab=info";
    }

    return url;
  }

  public async OpenFolder(folderID: number, organizationID: number) {
    const url = this.GetOpenOrgFolderUrl(folderID, organizationID);
    await this.navigateToUrl(url);
  }

  public GetOpenOrgFolderUrl(folderID: number, organizationID: number) {
    return (
      "organization/" +
      organizationID.toString() +
      "?folderId=" +
      folderID.toString() +
      "&activeTab=info"
    );
  }

  public async OpenUser(userID: number, orgID: number) {
    const url = this.GetOpenUserUrl(userID, orgID);
    await this.navigateToUrl(url);
  }

  public GetOpenUserUrl(userID: number, orgID: number): string {
    return "user/" + userID.toString() + "?orgId=" + orgID.toString();
  }

  public showDownloadSetupDialog(checkAlreadyShow: boolean) {
    let showDialog = true;
    const loggedInUser = this.loggedInInfoService.GetLoggedInUser();
    if (checkAlreadyShow) {
      if (loggedInUser) {
        showDialog = !loggedInUser.ShowSetupWizardDownload;
      }
    }

    if (this.downloadSetupDialogCloseSub) {
      this.downloadSetupDialogCloseSub.unsubscribe();
      this.downloadSetupDialogCloseSub = null;
    }

    if (showDialog && !loggedInUser.HideSetupWizardLinks) {
      this.notificationDialogService.ShowDownloadSetupWizard();
    }
  }

  public async OpenOrgToPackagePageInventoryState(
    orgId: number,
    subOrgId?: number,
  ) {
    const url = `organization/${orgId}?subOrgId=${subOrgId !== null ? subOrgId : orgId}&activeTab=packages&packageState=inventory`;
    await this.navigateToUrl(url);
  }

  public closeDownloadSetupDialog() {
    if (this.downloadSetupDialogRef) {
      this.dialogTrackService.closeDialog(this.downloadSetupDialogRef);
    }
  }
  private async navigateToUrl(url: string) {
    if (!this.router.url.startsWith(url)) {
      await this.router.navigateByUrl(url);
    }
  }

  private downloadSetupDialogCloseSub: Subscription;
}
