<app-loader
  *ngIf="loading"
  text="Loading..."
  class="padded"
  inline="true"
></app-loader>
<p-accordion>
  <p-accordionTab
    data-testid="invoiceRow"
    *ngFor="let tab of tabs"
    header="{{ tab.heading }}"
    [selected]="tab.selected"
  >
    <div
      data-testid="noInvoiceMsg"
      *ngIf="tab.invoices.length === 0"
      class="text-center"
    >
      No invoices found for this period
    </div>

    <table
      data-testid="invoiceTable"
      *ngIf="tab.invoices.length > 0"
      class="table"
    >
      <thead>
        <tr>
          <th data-testid="invoiceDateHeader">Invoice Date</th>
          <th data-testid="invoiceNumberHeader">Invoice Number</th>
          <th data-testid="invoicePriceHeader">Amount</th>
          <th data-testid="invoiceDownloadHeader">Download</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let invoice of tab.invoices">
          <td data-testid="invoiceDate" class="invoice-list-date-column">
            {{ invoice.invoiceDate | date: "MM/dd/yyyy" }}
          </td>
          <td data-testid="invoiceNumber">{{ invoice.invoiceNumber }}</td>
          <td data-testid="invoicePrice" class="invoice-list-amount-column">
            {{ invoice.amount | currency }}
          </td>
          <td
            data-testid="invoiceDownload"
            class="invoice-list-download-column"
          >
            <a
              tabindex="0"
              (keyup.enter)="
                getInvoicePdf(
                  invoice.pdfFileId,
                  invoice.invoiceNumber,
                  invoice.invoiceDate
                )
              "
              (click)="
                getInvoicePdf(
                  invoice.pdfFileId,
                  invoice.invoiceNumber,
                  invoice.invoiceDate
                )
              "
              class="c-pointer"
              ><i class="fa fa-file-pdf-o"></i
            ></a>
          </td>
        </tr>
      </tbody>
    </table>
  </p-accordionTab>
</p-accordion>
