<span
  tabindex="0"
  (keyup.enter)="toggleShoppingCartHandler()"
  data-testid="shoppingCartIcon"
  [ngClass]="
    getTotalShoppingCartItems() > 0 ? 'fa-stack c-pointer' : 'emptyCart'
  "
  attr.data-count="{{ getTotalShoppingCartItems() }}"
  (click)="toggleShoppingCartHandler()"
  tooltipPosition="bottom"
  pTooltip="Open cart"
>
  <i class="fa fa-shopping-cart shoppingCartIcon + {{ cartClass }}"></i>
</span>
