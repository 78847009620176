import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { RnToastService } from "../services/toast/rntoast.service";
import { ConstantsService } from "../services/constants/constants.service";

@Injectable()
export class HttpError {
  // can add more codes later, if we want more specialized error handling
  static NotFound = 404;
  static InternalServerError = 500;
}

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  // error codes that should not show a 500 error toast
  // 21002 - invalid username or password
  // 21011 - org suspended or cancelled
  // 28000 - order would reduce number of packages to 0
  exemptErrorCodes: string[] = ["21002", "21011", "28000"];

  constructor(
    private toastService: RnToastService,
    private constantsService: ConstantsService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event) => {
          // request handled appropriately, so nothing to do here
        },
        (exception) => {
          if (exception instanceof HttpErrorResponse) {
            if (
              exception.error.Messages?.length > 0 &&
              this.exemptErrorCodes.includes(exception.error.Messages[0].Code)
            ) {
              // ignore the exception; probably a failed login, cancelled org, etc.
            } else {
              switch (exception.status) {
                case HttpError.InternalServerError:
                  {
                    // I would advise NOT to use cosmos for this message. If the DSL is down,
                    // this will cause an infinite loop as it will repeatedly get 500 errors
                    const message =
                      this.constantsService.http500InternalServerErrorMessage;
                    this.toastService.showError(message);
                  }
                  break;

                default:
                  break;
              }
            }
          }
        },
      ),
    );
  }
}
