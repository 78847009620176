/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnOrganizationLicensesVM } from "../models/./organizationLicensesVM-d";
import { RnOrganizationUsersVM } from "../models/./organizationUsersVM-d";
import { RnOrganizationBreadcrumb } from "../models/./organizationBreadcrumb-d";
import { RnUsersVM } from "../models/./usersVM-d";
import { RnOrganizationAddressesVM } from "../models/./organizationAddressesVM-d";
import { RnOrganizationMaintenancePeriodsVM } from "../models/./organizationMaintenancePeriodsVM-d";
import { RnOrganizationTreeNode } from "../models/./organizationTreeNode-d";

export class RnOrganizationsProfileVM {
  ID?: number;
  Name?: string | null;
  CustomerType?: string | null;
  PartnerName?: string | null;
  CustomerAccountNumber?: string | null;
  AccountOwner?: string | null;
  AccountOwnerInfo?: RnUsersVM;
  AccountOwnerID?: number;
  ExternalID?: string | null;
  Status?: string | null;
  StatusDesc?: string | null;
  WlpStatus?: string | null;
  WlpStatusDesc?: string | null;
  CreatedDate?: string;
  CreatedTodayInZuoraTimeZone?: boolean;
  HasAPromotion?: boolean;
  ParentOrganizationStructure?: string | null;
  ChildOrganizationStructure?: Array<RnOrganizationTreeNode> | null;
  HasClientOrgs?: boolean;
  PaymentOnFileID?: number;
  HasDirectPaymentOnFile?: boolean;
  DirectPaymentOnFileStateName?: string | null;
  ParentID?: number;
  IdThatPaysForThisOrg?: number;
  ParentName?: string | null;
  UserCount?: number;
  Address?: RnOrganizationAddressesVM;
  Breadcrumb?: Array<RnOrganizationBreadcrumb> | null;
  DefaultConnectPoint?: string | null;
  DefaultSharedDrive?: string | null;
  OrgUnit?: string | null;
  OrgFolderId?: number;
  AccountSecurityGroup?: string | null;
  TerminalServicesGroup?: string | null;
  AppPoolID?: number;
  AppPoolName?: string | null;
  HomeFolderPath?: string | null;
  ProfileFolderPath?: string | null;
  Users?: Array<RnOrganizationUsersVM> | null;
  Licenses?: Array<RnOrganizationLicensesVM> | null;
  ZuoraID?: string | null;
  SalesAgentName?: string | null;
  MaintenanceInfo?: RnOrganizationMaintenancePeriodsVM;
  LeadSource?: string | null;
  DoesMyParentHaveAPOF?: boolean;
  RNSecurityState?: string | null;
  AllowPromoCodeInput?: boolean;
  FirstBillDateTodayInZuoraTimeZone?: boolean;
  PayingOrgInWriteOff?: boolean;
  AllowSalesforceChat?: boolean;
  WhiteLabelPartnerID?: number;
}
